import { Box, Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'
import { useUserContext } from 'components/Contexts/UserContext'
import DropDownAcessoNegociacoes from 'pages/Role/components/DropDownAcessoNegociacoes'

export default function Clientes() {
  const { role, changeRolePermission } = useRoleContext()
  const { clientes } = role?.permissoes || {}
  const { configuracoes } = useUserContext()
  return (
    <Box>
      <CardConfig
        title="Acesso (Ver)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder ver os
            clientes / leads (as regras se aplicam aos relatórios)
          </div>
        }
        component={
          <DropDownAcessoNegociacoes
            name="clientes.read"
            value={clientes?.read || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Criar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder criar os
            clientes / leads
          </div>
        }
        component={
          <Switch
            name="clientes.create"
            value={clientes?.create || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Alterar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder alterar os
            clientes / leads
          </div>
        }
        component={
          <Switch
            name="clientes.alter"
            value={clientes?.alter || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Deletar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder deletar os
            clientes / leads
          </div>
        }
        component={
          <Switch
            name="clientes.delete"
            value={clientes?.delete || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Vincular"
        description={
          <div>
            Define se o usuário pode realizar o vínculos de outras entidades com
            o cadastro de cliente.
          </div>
        }
        component={
          <Switch
            name="clientes.vincular"
            checked={Boolean(clientes?.vincular)}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      {Boolean(configuracoes?.usa_verificacao_spc) && (
        <CardConfig
          title="Aprovar Cadastro"
          description={
            <div>Define se o usuário pode aprovar cadastro de cliente.</div>
          }
          component={
            <Switch
              name="clientes.aprovar_cadastro"
              checked={Boolean(clientes?.aprovar_cadastro)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
      )}
    </Box>
  )
}
