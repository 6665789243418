import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'
import {
  OperacaoEnum,
  PedidoFilters,
  PedidoFilters1,
} from 'hooks/queries/usePedidos'
import { TextField, Stack, Autocomplete, MenuItem } from '@mui/material'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import { useUserContext } from 'components/Contexts/UserContext'
import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import { useEffect } from 'react'
import { DateTime } from 'luxon'
import DateRangePicker, { DateRange } from 'components/Inputs/DateRangePicker'

export const situacoes = [
  {
    value: 'N',
    label: 'Em aberto',
  },
  {
    value: 'CR',
    label: 'Cotação / Reaberta',
  },
  {
    value: 'T',
    label: 'Cotação / Solicitação',
  },
  {
    value: 'R',
    label: 'Cotação / Análise',
  },
  {
    value: 'U',
    label: 'Cotação / Aprovada',
  },
  {
    value: 'E',
    label: 'Fechado',
  },
  {
    value: 'A',
    label: 'Análise',
  },
  {
    value: 'L',
    label: 'Liberado',
  },
  {
    value: 'F',
    label: 'Faturado',
  },
  {
    value: 'O',
    label: 'Orçamento',
  },
  {
    value: 'S',
    label: 'Negociação Perdida',
  },
]

const situacoesFunil = [
  {
    value: 'N',
    label: 'Em negociação',
  },
  {
    value: 'E,A,L',
    label: 'Negociação ganha',
  },
  {
    value: 'S',
    label: 'Negociação perdida',
  },
]

export default function FilterForm(props: any) {
  const { onChangeFilterValue, changeFilterValue } = useFilterApi()
  const { user, temPermissao } = useUserContext()
  const { filterValues, isOpen } = useFilterData<
    PedidoFilters,
    PedidoFilters1
  >()

  const permissaoParaLerClientes = temPermissao('clientes.read') || 'pessoal'
  useEffect(() => {
    if (permissaoParaLerClientes === 'pessoal' && isOpen) {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerClientes, isOpen])

  return (
    <FilterContainer
      drawerProps={{
        sx: {
          zIndex: (theme) => theme.zIndex.drawer + 500,
        },
      }}
      title="Pesquisar por pedidos"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'Vendedor' || filterLabel === 'Cliente') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }
        if (filterLabel === 'Situação') {
          const label = filterValue.length === 1 ? 'Situação' : 'Situações'
          let values = ''
          if (Array.isArray(filterValue)) {
            values = filterValue.map((f: any) => f.label).toString()
          }
          return `${label} - ${values}`
        }

        if (filterLabel === 'Período') {
          const dateRange = filterValue as DateRange
          const formattedFirstDate = DateTime.fromFormat(
            dateRange?.firstDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          const formattedSecondDate = DateTime.fromFormat(
            dateRange?.secondDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          if (dateRange.tipo === 'day') {
            return `${filterLabel} - ${formattedFirstDate}`
          }

          return `${filterLabel} - de ${formattedFirstDate} até ${formattedSecondDate}`
        }

        if (filterLabel === 'Operação') {
          const operacoesMap: Record<string, string> = {
            V: 'Venda',
            B: 'Bonificação',
          }
          return `${filterLabel} - ${operacoesMap[filterValue]}`
        }

        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={1.5}>
        <DateRangePicker
          onChange={(value) => {
            changeFilterValue('dateRange', value)
          }}
          value={filterValues?.dateRange?.value}
        />

        <TextField
          name="codigo"
          value={filterValues?.codigo?.value || ''}
          label={filterValues?.codigo?.label}
          onChange={onChangeFilterValue}
        />
        <Autocomplete
          multiple
          options={props.tipo === 'tabela' ? situacoes : situacoesFunil}
          getOptionLabel={(option) => option?.label}
          value={filterValues?.situacaoKeyValue?.value || []}
          disableCloseOnSelect
          onChange={(_: any, value: any) =>
            changeFilterValue('situacaoKeyValue', value)
          }
          renderInput={(params) => <TextField {...params} label="Situação" />}
        />
        <TextField
          name="operacao"
          value={filterValues?.operacao?.value}
          label={filterValues?.operacao?.label}
          onChange={onChangeFilterValue}
          select
        >
          <MenuItem value={OperacaoEnum.VENDA}>Venda</MenuItem>
          <MenuItem value={OperacaoEnum.BONIFICACAO}>Bonificação</MenuItem>
        </TextField>
        <TextField
          name="carga"
          value={filterValues?.carga?.value || ''}
          label={filterValues?.carga?.label}
          onChange={onChangeFilterValue}
        />
        <TimeVendasTransporter
          disabled={temPermissao('clientes.read') === 'pessoal'}
          name="vendedorUuid"
          label="Vendedor"
          value={filterValues?.representante?.value || null}
          onChange={(representante) => {
            changeFilterValue('representante', representante)
          }}
          placeholder="Selecione um vendedor"
          InputLabelProps={{ shrink: true }}
        />
        <ClientesTransporter
          label="Cliente"
          name="clienteUuid"
          value={filterValues?.cliente?.value || null}
          vendedorUuid={
            permissaoParaLerClientes === 'pessoal' ? user?.uuid : undefined
          }
          onChange={(cliente) => {
            changeFilterValue('cliente', cliente)
          }}
          placeholder="Selecione um cliente"
          InputLabelProps={{ shrink: true }}
        />
      </Stack>
    </FilterContainer>
  )
}
