import {
  PlainQueryProps,
  HttpResponseInterface,
  HttpRequestInterface,
} from '@data-c/hooks'
import api from 'services/api'
import { FilterOptions } from '@data-c/providers'
import useCrud from 'hooks/useCrud'
import { AxiosError } from 'axios'
import { ClasseProdutosModel } from './useClasseProdutos'
import { FamiliaProdutosModel } from './useFamiliaProdutos'
import { GrupoProdutosModel } from './useGrupoProdutos'
import { LinhaProdutosModel } from './useLinhaProdutos'
import { TipoVariacaoEnum } from 'components/Inputs/DropDownTipoVariacao'

export interface ProdutoModel {
  uuid: string
  nome: string
  codigo: string
  descricao: string
  unidade: string
  fatorunidade?: number
  fracionado: boolean
  ativo: boolean
  produtoClasse?: ClasseProdutosModel | null
  produtocls?: string | null
  produtofml?: string | null
  familiaProduto?: FamiliaProdutosModel | null
  produtogrp?: string | null
  grupoProduto?: GrupoProdutosModel | null
  produtolnh?: string | null
  linhaProduto?: LinhaProdutosModel | null
  pesobruto?: number
  pesoliquido?: number
  pDescontomax?: number
  pAcrescimomax?: number
  pontos?: number
  produtochave?: string
  pbonificacao?: number
  valorMinimo?: number
  quantidadeMinima?: number
  percentualAcrescimo?: number
  fracionadoWeb?: number
  tipoVariacao?: TipoVariacaoEnum
}

export interface ProdutoFilters {
  nome: FilterOptions<string>
}

export interface ProdutoFilters1 {
  nome: string
  plainQuery?: string
}

export const filters: ProdutoFilters = {
  nome: {
    label: 'Nome',
  },
}

export async function obterProdutos(
  params: HttpRequestInterface<PlainQueryProps>,
): Promise<HttpResponseInterface<ProdutoModel>> {
  const { pagination: _pagination, queryParams } = params

  const response = await api.get('administrador/produtos', {
    params: queryParams,
    headers: {
      'DC-Page': _pagination.page,
      'DC-PageSize': _pagination.pageSize,
    },
  })

  const { data, meta: pagination } = response.data

  return { data, pagination }
}

export default function useProdutos() {
  return useCrud<ProdutoModel, AxiosError>('administrador/produtos', 'Produto')
}
