import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'
import { Stack, TextField, MenuItem } from '@mui/material'
import DateRangePicker, { DateRange } from 'components/Inputs/DateRangePicker'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import { DateTime } from 'luxon'

export default function Filter() {
  const { changeFilterValue } = useFilterApi()

  const { filterValues } = useFilterData()

  return (
    <FilterContainer
      disableds={[
        'percentualA',
        'percentualB',
        'percentualC',
        'situacaoPedido',
        'dateRange',
      ]}
      title="Parâmetros"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel, filterValue) => {
        if (filterLabel === 'Vendedor') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Situação') {
          return `Situação - ${
            filterValue === 'emissao' ? 'Faturados' : 'Em Processamento'
          }`
        }

        if (filterLabel.startsWith('Percentual')) {
          return `${filterLabel.split(' ')[1]} - ${filterValue}%`
        }

        if (filterLabel === 'Período') {
          const dateRange = filterValue as DateRange
          const formattedFirstDate = DateTime.fromFormat(
            dateRange?.firstDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          const formattedSecondDate = DateTime.fromFormat(
            dateRange?.secondDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          if (dateRange.tipo === 'day') {
            return `${filterLabel} - ${formattedFirstDate}`
          }

          return `${filterLabel} - de ${formattedFirstDate} até ${formattedSecondDate}`
        }

        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={1.5}>
        <DateRangePicker
          onChange={(value) => {
            changeFilterValue('dateRange', value)
          }}
          value={filterValues?.dateRange?.value}
        />
        <TextField
          select
          label="Situação do Pedido"
          value={filterValues?.situacaoPedido?.value || ''}
          onChange={(e) => changeFilterValue('situacaoPedido', e.target.value)}
        >
          <MenuItem value="emissao">Faturados</MenuItem>
          <MenuItem value="venda">Em processamento</MenuItem>
        </TextField>

        <TextField
          type="number"
          label="A"
          value={filterValues?.percentualA?.value || ''}
          onChange={(e) => changeFilterValue('percentualA', e.target.value)}
        />
        <TextField
          type="number"
          label="B"
          value={filterValues?.percentualB?.value || ''}
          onChange={(e) => changeFilterValue('percentualB', e.target.value)}
        />
        <TextField
          type="number"
          label="C"
          value={filterValues?.percentualC?.value || ''}
          onChange={(e) => changeFilterValue('percentualC', e.target.value)}
        />
        <TimeVendasTransporter
          name="representanteUuid"
          label={filterValues?.representante?.label}
          value={filterValues?.representante?.value || null}
          onChange={(representante) => {
            changeFilterValue('representante', representante)
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Stack>
    </FilterContainer>
  )
}
