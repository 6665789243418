import { useEffect } from 'react'
import { Grid, TextField } from '@mui/material'

import { Checkbox, FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import useTabelaPreco, { TabelaPrecoModel } from 'hooks/queries/useTabelaPreco'
import useValidations from 'hooks/useValidations'

import DropDownOperacaoPadrao from 'components/Inputs/DropDownOperacaoPadrao'
import DropDownFatorPromocional from 'components/Inputs/DropDownFatorPromocional'

import * as yup from 'yup'
import FormProps from 'interfaces/FormProps'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o nome'),
})

export default function Form(props: FormProps<TabelaPrecoModel>) {
  const { data: tabelaPreco, view = 'dialog' } = props

  const {
    onChangeFormValue,
    changeValue,
    changeValues,
    closeForm,
    toggleSubmit,
  } = useFormApi<TabelaPrecoModel>()
  const { formValues: data } = useFormData<TabelaPrecoModel>()

  useEffect(() => {
    if (tabelaPreco) {
      changeValues(tabelaPreco)
    }
  }, [])

  const { setValidationErrors, validationProps } = useValidations()
  const { useSubmit } = useTabelaPreco()
  const { mutateAsync } = useSubmit()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      dialogProps={{
        title: 'Cadastro Tabela de Preço',
        disableRestoreFocus: true,
      }}
      onSubmitForm={handleSubmitForm}
      view={view}
    >
      <Grid>
        <Grid container spacing={2}>
          <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
            <TextField
              name="codigo"
              label="Código"
              value={data?.codigo || ''}
              onChange={onChangeFormValue}
              disabled
            />
          </Grid>
          <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
            <TextField
              autoFocus
              name="nome"
              label="Nome"
              required
              value={data?.nome || ''}
              onChange={onChangeFormValue}
              inputProps={{ maxLength: 40 }}
              {...validationProps('nome')}
            />
          </Grid>
          <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
            <DropDownOperacaoPadrao
              name="operacaoPadrao"
              label="Operação Padrão"
              value={data?.operacaoPadrao || ''}
              onChange={onChangeFormValue}
            />
          </Grid>
          <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
            <DropDownFatorPromocional
              name="fatorPromocionalUuid"
              label="Fator Promocional"
              value={data?.fatorPromocionalUuid || -1}
              onChange={(e) => {
                const fatorPromocionalUuid =
                  parseInt(e.target.value) === -1 ? null : e.target.value
                changeValue('fatorPromocionalUuid', fatorPromocionalUuid)
              }}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
            <Checkbox
              label="Ativo"
              name="ativo"
              onChange={onChangeFormValue}
              checked={Boolean(data?.ativo)}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormContainer>
  )
}

Form.defaultProps = {
  data: {},
}
