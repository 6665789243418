import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'

import { Stack, TextField } from '@mui/material'
import { ProdutoFilters, ProdutoFilters1 } from 'hooks/queries/useProdutos'
import FilterProps from 'interfaces/FilterProps'

export default function Filter({
  onApplyFilters,
}: FilterProps<ProdutoFilters1>) {
  const { onChangeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<ProdutoFilters, ProdutoFilters1>()
  return (
    <FilterContainer
      title="Pesquisar por produtos"
      triggerButtonProps={{ variant: 'outlined' }}
      onApplyFilters={(f) => onApplyFilters(f)}
    >
      <Stack spacing={1.5}>
        <TextField
          name="nome"
          value={filterValues?.nome?.value}
          label={filterValues?.nome?.label}
          onChange={onChangeFilterValue}
        />
      </Stack>
    </FilterContainer>
  )
}
