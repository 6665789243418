import api from 'services/api'
import { useQuery } from '@tanstack/react-query'
import ErrorInterface from 'interfaces/ErrorInterface'
import { AxiosError } from 'axios'
import { FilterOptions } from '@data-c/providers'
import { SituacaoInterface } from './usePedidos'
import { TimeVendasModel } from './useTimeVendas'
import { SortProps } from 'interfaces/SortProps'
import { DateTime } from 'luxon'
import { DateRange } from 'components/Inputs/DateRangePicker'

export interface RelatorioMetaVendasModel {
  codigoVendedor: string
  nomeVendedor: string
  totalVendido: number
  metaDeVenda: number
  porcentagemAtingidaDaMetaDeVenda: number
}

export interface RelatorioMetaVendasFilters {
  anoMes?: FilterOptions<string>
  situacao: FilterOptions<Array<SituacaoInterface>>
  representante?: FilterOptions<TimeVendasModel | null>
  representanteUuid?: FilterOptions<string | null>
  tipoData?: FilterOptions<'venda' | 'emissao'>
  dateRange?: FilterOptions<DateRange>
  dataInicial?: FilterOptions<string>
  dataFinal?: FilterOptions<string>
}

export interface RelatorioMetaVendasFilters1 {
  tipoData?: 'venda' | 'emissao'
  anoMes?: string
  situacao?: Array<SituacaoInterface>
  representante?: TimeVendasModel
  representanteUuid?: string
  dateRange?: DateRange
  dataInicial?: string | null
  dataFinal?: string | null
}

export const filters: RelatorioMetaVendasFilters = {
  dataInicial: {
    label: 'Data inicial',
  },
  dataFinal: {
    label: 'Data final',
  },
  tipoData: {
    label: 'Tipo Data',
    value: 'emissao',
  },
  situacao: {
    label: 'Situação',
    value: [
      {
        label: 'Faturado',
        value: 'F',
      },
    ],
  },
  representante: {
    label: 'Vendedor',
    value: null,
  },
  representanteUuid: {
    value: '',
    label: 'Vendedor',
  },
  dateRange: {
    label: 'Período',
    value: {
      tipo: 'month',
      firstDate: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
      secondDate: DateTime.now().endOf('day').toFormat('yyyy-MM-dd'),
    },
  },
}

export async function obterRelatorioMetaVendas(
  params: RelatorioMetaVendasFilters1,
  sort?: SortProps,
): Promise<Array<RelatorioMetaVendasModel>> {
  const situacoes = params?.situacao
    ? params?.situacao.map((s) => s.value)
    : null

  const newParams = {
    ...params,
    situacao: situacoes,
  }

  const response = await api.get<Array<RelatorioMetaVendasModel>>(
    `relatorio/meta-vendas`,
    {
      params: newParams,
      headers: {
        'DC-SortName': sort?.column,
        'DC-SortDirection': sort?.direction,
      },
    },
  )
  return response.data
}

export function useQueryObterRelatorioMetaVendas(
  params: RelatorioMetaVendasFilters1,
  sort?: SortProps,
) {
  return useQuery<Array<RelatorioMetaVendasModel>, AxiosError<ErrorInterface>>(
    ['RELATORIO_META_VENDAS', params, sort],
    () => {
      return obterRelatorioMetaVendas(params, sort)
    },
  )
}

export default function useRelatorioMetaVendas() {
  return {
    useQueryObterRelatorioMetaVendas,
  }
}
