import { useDialog } from '@data-c/hooks'
import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import { Check, MoreHoriz, Delete } from '@mui/icons-material'
import { IconButton, MenuItem, Popover, Stack, Typography } from '@mui/material'
import usePopOver from 'hooks/usePopover'
import useAppNotifications, {
  AppNotificationsModel,
} from 'hooks/queries/useAppNotifications'

interface ItemOptionsProps {
  notificacao: AppNotificationsModel
  mouseIn: boolean
}

export function ItemOptions(props: ItemOptionsProps) {
  const { notificacao, mouseIn } = props

  const { useUpdateNotificationStatus, useDeleteNotification } =
    useAppNotifications()
  const { mutateAsync: updateNotificationStatus } =
    useUpdateNotificationStatus()
  const { mutateAsync: deleteNotification, isLoading } = useDeleteNotification()

  const { isOpen: isOpenDialog, openDialog, closeDialog } = useDialog()

  const {
    anchorEl,
    close: closePopOver,
    isOpen: isOpenPopOver,
    open: openPopOver,
  } = usePopOver()

  async function handleUpdateNotificationStatus() {
    const lido = notificacao.lido === true ? false : true
    closePopOver()
    await updateNotificationStatus({ ...notificacao, lido })
  }

  async function handleDeleteNotification() {
    closePopOver()
    await deleteNotification(notificacao)
    closeDialog()
  }

  return (
    <>
      <ButtonContainer sx={{ marginTop: '-24px', visibility: mouseIn ? 'visible' : 'hidden', }}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            openPopOver(e)
          }}
        >
          <MoreHoriz />
        </IconButton>
      </ButtonContainer>

      <Popover
        open={isOpenPopOver}
        onClose={closePopOver}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MenuItem
          sx={{ m: 1 }}
          onClick={(e) => {
            e.stopPropagation()
            handleUpdateNotificationStatus()
          }}
        >
          <Stack direction="row" gap={1}>
            <Check />
            <Typography variant="body2">
              {notificacao.lido ? 'Marcar como não lida' : 'Marcar como lida'}
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          sx={{ m: 1 }}
          onClick={(e) => {
            e.stopPropagation()
            openDialog()
          }}
        >
          <Stack direction="row" gap={1}>
            <Delete />
            <Typography variant="body2">Excluir notificação</Typography>
          </Stack>
        </MenuItem>
      </Popover>

      <Dialog
        open={isOpenDialog}
        type="error"
        title="Excluir Notificação Permanentemente?"
        maxWidth="xs"
        actions={
          <ButtonContainer>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                closeDialog()
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="error"
              isLoading={isLoading}
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteNotification()
              }}
            >
              Excluir
            </Button>
          </ButtonContainer>
        }
      >
        Você não poderá recuperar esta notificação após excluí-la!
      </Dialog>
    </>
  )
}
