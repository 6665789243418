import { Stack, Typography } from '@mui/material'
import { AppNotificationsModel } from 'hooks/queries/useAppNotifications'
import notifyImage from 'assets/images/notify.svg'

interface EmptyNotificationsProps {
  isFetching: boolean
  notifications: AppNotificationsModel[]
}

export default function EmptyNotifications(props: EmptyNotificationsProps) {
  const { isFetching, notifications } = props

  return (
    <>
      {!isFetching && notifications.length === 0 && (
        <Stack
          height="150px"
          padding={1}
          alignItems="center"
          gap={3}
          justifyContent="center"
        >
          <img src={notifyImage} alt="Imagem de notificação" width="30%" />

          <Typography variant="h5">Você não tem notificações</Typography>
        </Stack>
      )}
    </>
  )
}
