import { Button, theme } from '@data-c/ui'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'

interface HeaderProps {
  notificationStatus: 'todas' | 'naoLidas'
  isFetching?: boolean
  onClick: (event: 'todas' | 'naoLidas') => void
}

export default function Header(props: HeaderProps) {
  const { notificationStatus, onClick, isFetching } = props

  return (
    <Stack gap={2} sx={{ padding: '16px 16px 0 16px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="h4" fontWeight="bold">
          Notificações
        </Typography>

        {isFetching && <CircularProgress size={16} />}
      </Box>

      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          variant="text"
          onClick={() => onClick('todas')}
          sx={{
            color: `${
              notificationStatus === 'todas'
                ? theme.palette.primary.main
                : theme.palette.grey[500]
            }`,
          }}
        >
          Todas
        </Button>
        <Button
          variant="text"
          onClick={() => onClick('naoLidas')}
          sx={{
            color: `${
              notificationStatus === 'naoLidas'
                ? theme.palette.primary.main
                : theme.palette.grey[500]
            }`,
          }}
        >
          Não lidas
        </Button>
      </Box>
    </Stack>
  )
}
