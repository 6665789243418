import { useCallback, useEffect, useMemo } from 'react'
import { LabelValue } from '@data-c/ui'
import {
  Paper,
  Grid,
  Box,
  Typography,
  TextField,
  MenuItem,
} from '@mui/material'

import {
  PedidoModel,
  SituacaoPedidoEnum,
  ajustesMap,
  useUpdatePedido,
} from 'hooks/queries/usePedidos'
import useForm from 'hooks/useForm'

import AutoSaverTextField from 'components/AutoSaverTextField'
import TabelaPrecoTransporter from 'components/Transporters/TabelaPrecoTransporter'
import CondicaoPagamentoTransporter from 'components/Transporters/CondicaoPagamentoTransporter'
import FormaPagamentoTransporter from 'components/Transporters/FormaPagamentoTransporter'
import { ConfiguracoesModel } from 'hooks/queries/useConfiguracoes'
import FeedbackRequestStatus from 'components/FeedbackRequestStatus'
import DatePicker from 'components/DatePicker'
import { useUserContext } from 'components/Contexts/UserContext'
import InfoCliente from './components/InfoCliente'
import { ClientesModel } from 'hooks/queries/useClientes'

export interface HeaderPedidoProps {
  pedido: PedidoModel
  configuracoes?: ConfiguracoesModel
}

export function HeaderPedido(props: HeaderPedidoProps) {
  const { pedido, configuracoes } = props
  const { user, temPermissao } = useUserContext()
  const { data, handleChange, setData, changeValue } = useForm<PedidoModel>(
    {} as PedidoModel,
  )

  const podeEditarPedido = temPermissao('negociacoes.alter') != 'sem_acesso'

  const {
    mutateAsync: updatePedido,
    isLoading,
    isSuccess,
    error,
  } = useUpdatePedido()

  useEffect(() => {
    setData(pedido)
  }, [pedido, setData])

  const salvarPedido = useCallback(
    async (_data: PedidoModel) => {
      await updatePedido(_data)
    },
    [updatePedido],
  )

  const podeEditar = useMemo(() => {
    const naoPodeEditar =
      data.situacao === SituacaoPedidoEnum.NEGOCIACAO_PERDIDA ||
      data.situacao === SituacaoPedidoEnum.FECHADO ||
      data.situacao === SituacaoPedidoEnum.LIBERADO ||
      data.situacao === SituacaoPedidoEnum.ANALISE ||
      data.situacao === SituacaoPedidoEnum.CANCELADO

    if (naoPodeEditar) return false

    if (!podeEditarPedido) return false

    return true
  }, [data, podeEditarPedido])

  const podeEditarValidadeDaCotacao = user?.isadmin || false
  const useAjuste = configuracoes?.usa_ajuste || false
  const formaPagamentoGridSize = useAjuste ? 3 : 4

  return (
    <Paper sx={{ p: 2, mb: 1, position: 'relative' }} elevation={0}>
      <Box
        sx={{
          position: 'absolute',
          right: '20px',
        }}
      >
        <FeedbackRequestStatus
          value={data}
          isLoading={isLoading}
          isSuccess={isSuccess}
          error={Boolean(error?.message)}
          onRetry={(value: PedidoModel) =>
            salvarPedido({
              ...data,
              observacao: value?.observacao,
              validadeCotacao: value?.validadeCotacao || null,
            })
          }
        />
      </Box>

      <Grid container spacing={2}>
        <Grid item xl={1} lg={1} md={3} sm={6} xs={12}>
          <LabelValue label="Data">{pedido.vendaAsBrazilianDate}</LabelValue>
        </Grid>
        <Grid item xl={1} lg={1} md={3} sm={6} xs={12}>
          <LabelValue label="Código">{pedido.codigo}</LabelValue>
        </Grid>
        {/* <Grid item xl={1} lg={2} md={3} sm={6} xs={12}>
          <LabelValue label="Pedido relacionado">
            {pedido.pedidoRelacionado?.codigo}
          </LabelValue>
        </Grid> */}
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          {data.situacao === SituacaoPedidoEnum.NEGOCIACAO_PERDIDA ? (
            <LabelValue label="Situação" labelProps={{ color: 'error' }}>
              <Typography color="error">{pedido.situacaoAsText}</Typography>
            </LabelValue>
          ) : (
            <LabelValue label="Situação">{pedido.situacaoAsText}</LabelValue>
          )}
        </Grid>
        <Grid item xl={1} lg={1} md={4} sm={6} xs={12}>
          <LabelValue label="Operação">{pedido.operacaoAsText}</LabelValue>
        </Grid>
        <Grid item xl={1} lg={1} md={4} sm={6} xs={12}>
          {/* <LabelValue label="Ajuste">
            
          </LabelValue> */}
        </Grid>
        <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
          <LabelValue label="Vendedor">
            {`${pedido.representante?.codigo || ''} - ${
              pedido.representante?.nome || ''
            }`}
          </LabelValue>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <InfoCliente cliente={data?.cliente || ({} as ClientesModel)} />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <TabelaPrecoTransporter
            value={data?.tabelaPreco || null}
            clienteUuid={pedido.pessoa}
            vendedorUuid={pedido.vendedor}
            onChange={(value) => {
              setData({
                ...data,
                tabelaPreco: value,
                tabelaPrecoUuid: value?.uuid,
              })
              salvarPedido({
                ...data,
                tabelaPreco: value,
                tabelaPrecoUuid: value?.uuid,
              })
            }}
            isLoading={isLoading}
            error={Boolean(error?.message)}
            helperText={
              Boolean(error?.message) &&
              'Não foi possível salvar a tabela de preço mas você pode tentar novamente.'
            }
            disabled={!podeEditar}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CondicaoPagamentoTransporter
            value={data?.condicaoPagamento || null}
            clienteUuid={pedido.pessoa}
            vendedorUuid={pedido.vendedor}
            onChange={(value) => {
              setData({
                ...data,
                condicaoPagamento: value,
                condicaoPagamentoUuid: value?.uuid,
              })
              salvarPedido({
                ...data,
                condicaoPagamento: value,
                condicaoPagamentoUuid: value?.uuid,
              })
            }}
            disabled={!podeEditar}
            isLoading={isLoading}
            error={Boolean(error?.message)}
          />
        </Grid>
        {configuracoes?.usa_formapgto !== 0 && (
          <Grid
            item
            xl={formaPagamentoGridSize}
            lg={formaPagamentoGridSize}
            md={formaPagamentoGridSize}
            sm={12}
            xs={12}
          >
            <FormaPagamentoTransporter
              value={data?.formaPagamento || null}
              clienteUuid={pedido.pessoa}
              vendedorUuid={pedido.vendedor}
              onChange={(value) => {
                setData({
                  ...data,
                  formaPagamento: value,
                  formaPagamentoUuid: value?.uuid,
                })
                salvarPedido({
                  ...data,
                  formaPagamento: value,
                  formaPagamentoUuid: value?.uuid,
                })
              }}
              isLoading={isLoading}
              error={Boolean(error?.message)}
              disabled={!podeEditar}
            />
          </Grid>
        )}

        {Boolean(configuracoes?.usa_ajuste) && (
          <Grid item xl={1} lg={1} md={1} sm={12} xs={12}>
            <TextField
              select
              value={data?.ajuste || 0}
              label="Ajuste"
              onChange={(e) => {
                const ajuste = e.target.value
                setData({
                  ...data,
                  ajuste,
                })
                salvarPedido({
                  ...data,
                  ajuste,
                })
              }}
            >
              {Array.from(
                { length: (configuracoes?.ajuste || 0) + 1 },
                (_, i) => i,
              ).map((ajuste) => (
                <MenuItem value={ajustesMap[ajuste]}>{ajuste}</MenuItem>
              ))}
            </TextField>
            {/* <FormControlLabel
              disabled={data?.itens?.length > 0 || !podeEditar}
              control={
                <Switch
                  checked={data?.ajuste === 0}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.checked ? 0 : 100
                    changeValue('ajuste', value)
                    salvarPedido({
                      ...data,
                      ajuste: value,
                    })
                  }}
                />
              }
              label="Orçamento"
            /> */}
          </Grid>
        )}

        {pedido.iscotacao && (
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <DatePicker
              label="Validade da Cotação"
              value={data?.validadeCotacao || null}
              error={Boolean(error?.message)}
              onChange={(date: string | null) => {
                changeValue('validadeCotacao', date)
                salvarPedido({
                  ...data,
                  validadeCotacao: date,
                })
              }}
              disabled={!podeEditarValidadeDaCotacao || !podeEditar}
            />
          </Grid>
        )}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AutoSaverTextField
            name="observacao"
            label="Observação"
            value={data?.observacao || ''}
            extraParams={data}
            multiline
            rows={4}
            error={Boolean(error?.message)}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
              extraParams?: PedidoModel,
            ) => {
              handleChange(e)
              salvarPedido({
                ...(extraParams as PedidoModel),
                observacao: e.target.value,
              })
            }}
            disabled={!podeEditar}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
