import { useFilterApi, useFilterData } from '@data-c/providers'
import { Checkbox, FilterContainer } from '@data-c/ui'
import { Stack, TextField } from '@mui/material'
import {
  ItemTabelaPrecoFilter,
  ItemTabelaPrecoFilter1,
} from 'hooks/queries/useTabelaPreco'
import { useQuery } from 'hooks/queries/useConfiguracoes'
export default function Filter() {
  const { onChangeFilterValue } = useFilterApi()
  const { data: configuracoes } = useQuery()
  const { filterValues } = useFilterData<
    ItemTabelaPrecoFilter,
    ItemTabelaPrecoFilter1
  >()

  return (
    <FilterContainer
      title="Pesquisar por produtos da tabela"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'qtdMinima') {
          return 'Apenas grade fechada'
        }
        if (filterLabel === 'codigoNomeCor') {
          return `Cor - ${filterValue}`
        }
        if (filterLabel === 'codigoNomeGrade') {
          return `Grade - ${filterValue}`
        }
        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={2}>
        <TextField
          autoFocus
          name="codigoProduto"
          value={filterValues?.codigoProduto?.value}
          label={filterValues?.codigoProduto?.label}
          onChange={onChangeFilterValue}
        />
        <TextField
          name="nomeProduto"
          value={filterValues?.nomeProduto?.value}
          label={filterValues?.nomeProduto?.label}
          onChange={onChangeFilterValue}
        />
        {configuracoes?.modulo_pedido === 'COR' ||
          (configuracoes?.modulo_pedido === 'GRADE' && (
            <TextField
              name="codigoNomeCor"
              value={filterValues?.codigoNomeCor?.value}
              label={'Variação 1'}
              onChange={onChangeFilterValue}
              placeholder="Código ou nome da variação 1"
            />
          ))}

        {configuracoes?.modulo_pedido === 'GRADE' && (
          <TextField
            name="codigoNomeGrade"
            value={filterValues?.codigoNomeGrade?.value}
            label={'Variação 2'}
            onChange={onChangeFilterValue}
            placeholder="Código ou nome da variação 2"
          />
        )}

        <Checkbox
          name="apenasQuantidadeMinima"
          label="Apenas grade fechada"
          checked={filterValues?.apenasQuantidadeMinima?.value}
          onChange={onChangeFilterValue}
        />
      </Stack>
    </FilterContainer>
  )
}
