import { AppBar, Stack, styled, Toolbar, useTheme } from '@mui/material'

import AccountPopover from './components/AccountPopover'
import Notifications from 'components/Notifications'
import Logo from 'components/Logo'

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

export default function Header() {
  const theme = useTheme()
  return (
    <>
      <AppBar
        position="fixed"
        elevation={1}
        sx={{
          width: '100%',
          // background: '#FFF',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Logo />
          <Stack
            width="100%"
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <AccountPopover />
            <Notifications />
          </Stack>
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  )
}
