import { useNavigate, useParams } from 'react-router-dom'

import { Container, Tab, Tabs } from '@mui/material'

import { PageTitle } from '@data-c/ui'
import { FormProvider } from '@data-c/providers'

import useClientes, { ClientesModel } from 'hooks/queries/useClientes'
import useTabs from 'hooks/useTabs'

import TabPanel from 'components/TabPanel'
import Loading from 'components/Displays/Loading'
import TabVendedores from './components/TabVendedores'
import TabCadastroCliente from './components/TabCadastroCliente'
import TabTabelaDePrecos from './components/TabTabelaDePrecos'
import { useUserContext } from 'components/Contexts/UserContext'
import TabFormaDePagamento from './components/TabFormaDePagamento'
import Content from 'components/Layouts/Content'
import TabCondicaoDePagamento from './components/TabCondicaoDePagamento'
import TabDuplicatas from './components/TabDuplicatas'

export default function Cliente() {
  const { id: clienteUuid } = useParams()
  const { temPermissao } = useUserContext()
  const navigate = useNavigate()

  const { useQueryByUuid } = useClientes()
  const { data: cliente, isLoading } = useQueryByUuid<ClientesModel>(
    clienteUuid || '',
  )

  const { handleChange, value } = useTabs()

  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <FormProvider>
        <PageTitle
          title={`${cliente?.nome || cliente?.usual}`}
          onBack={() => navigate(-1)}
        >
          <Tabs value={value} onChange={handleChange} aria-label="tabs cliente">
            <Tab label="Cadastro" value={0} />
            {temPermissao('clientes.vincular') && (
              <Tab label="Vendedores" value={1} />
            )}
            {temPermissao('clientes.vincular') && (
              <Tab label="Tabela de Preços" value={2} />
            )}
            {temPermissao('clientes.vincular') && (
              <Tab label="Forma Pagamento" value={3} />
            )}

            {temPermissao('clientes.vincular') && (
              <Tab label="Condição Pagamento" value={4} />
            )}

            <Tab label="Duplicatas" value={5} />
          </Tabs>
        </PageTitle>

        <Content>
          <TabPanel value={value} index={0}>
            <TabCadastroCliente cliente={cliente || ({} as ClientesModel)} />
          </TabPanel>
          {temPermissao('clientes.vincular') && (
            <TabPanel value={value} index={1}>
              <TabVendedores clienteUuid={cliente?.uuid || ''} />
            </TabPanel>
          )}
          {temPermissao('clientes.vincular') && (
            <TabPanel value={value} index={2}>
              <TabTabelaDePrecos pessoaUuid={cliente?.uuid || ''} />
            </TabPanel>
          )}
          {temPermissao('clientes.vincular') && (
            <TabPanel value={value} index={3}>
              <TabFormaDePagamento pessoaUuid={cliente?.uuid || ''} />
            </TabPanel>
          )}

          {temPermissao('clientes.vincular') && (
            <TabPanel value={value} index={4}>
              <TabCondicaoDePagamento pessoaUuid={cliente?.uuid || ''} />
            </TabPanel>
          )}

          <TabPanel value={value} index={5}>
            <TabDuplicatas pessoaUuid={cliente?.uuid || ''} />
          </TabPanel>
        </Content>
      </FormProvider>
    </Container>
  )
}
