import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useUserContext } from 'components/Contexts/UserContext'
import { MioAuth } from '@data-c/ui'
import credentialsConfig from 'configs/credentials'
import useNotification from 'hooks/useNotifications'
import { Box, Container, LinearProgress, Paper } from '@mui/material'
import useDeviceDetect from 'hooks/useDeviceDetect'
import WebAppInterface from 'interfaces/WebAppInterface'
import { AmbienteDataInterface } from '@data-c/hooks'

declare const android: WebAppInterface
export default function Login() {
  const notification = useNotification()

  const [obtendoUsuarioLogado, setObtendoUsuarioLogado] = useState(false)
  const { loadUser, loadConfiguracoes } = useUserContext()
  const { isWebView } = useDeviceDetect()
  const navigate = useNavigate()

  async function handleLoginSuccess(tokens: AmbienteDataInterface) {
    if (isWebView) {
      try {
        android.logar(tokens.token, tokens.refreshToken)
      } catch (err) {}
      return
    }
    try {
      setObtendoUsuarioLogado(true)
      await loadUser()
      await loadConfiguracoes()
      navigate('/pedidos/lista')
    } catch (err) {
      notification.notifyException(err)
    } finally {
      setObtendoUsuarioLogado(false)
    }
  }

  if (obtendoUsuarioLogado) {
    return (
      <Box sx={{ height: '100vh' }}>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            height: '80%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <Paper sx={{ p: 4 }}>
            <Box
              sx={{
                marginTop: 3,
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LinearProgress />
              <div>Carregando informações da conta</div>
            </Box>
          </Paper>
        </Container>
      </Box>
    )
  }

  return (
    <>
      {!obtendoUsuarioLogado && (
        <MioAuth
          appName="Força de Vendas"
          credentialsConfig={credentialsConfig}
          onLoginSuccess={handleLoginSuccess}
          onAlreadyLogged={() => {
            // navigate('/pedidos/lista')
          }}
          onLoginFailed={() => {}}
        />
      )}
    </>
  )
}
