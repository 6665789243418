import { useMemo, useState } from 'react'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Stack } from '@mui/material'

import { Button, DataTable, Flag } from '@data-c/ui'
import { formatCurrency, useDialog, usePagination } from '@data-c/hooks'

import useTabelaPreco, {
  ItemTabelaPrecoModel,
  ItemTabelaPrecoFilter1,
  UpdateVinculoProdutosInputProps,
} from 'hooks/queries/useTabelaPreco'

import CurrencyTextButton from 'components/Inputs/CurrencyTextButton'
import InteractiveCell from './components/InteractiveCell'
import { ConfirmationDialog } from './components/ConfirmationDialog'
import { useFilterData } from '@data-c/providers'
import { useQuery } from 'hooks/queries/useConfiguracoes'
import CheckboxButton from 'components/Inputs/CheckboxButton'
export interface TableProps {
  tabelaPrecoUuid: string
}

function temProdutosSelecionados(
  indexProdutosSelecionados: Array<string | undefined>,
): boolean {
  return indexProdutosSelecionados.length > 0
}

export default function Table(props: TableProps) {
  const { tabelaPrecoUuid } = props
  const { data: configuracoes } = useQuery()
  const { appliedValues, searchId } = useFilterData<ItemTabelaPrecoFilter1>()

  const [quantidadeMinima, setQuantidadeMinima] = useState<number>(0)
  const [precoGlobal, setPrecoGlobal] = useState<number>(0)
  const [comissaoGlobal, setComissaoGlobal] = useState<number>(0)
  const [indexProdutosSelecionados, setIndexProdutosSelecionados] = useState<
    number[]
  >([])

  const { changePageSize, changePage, pagination } = usePagination()
  const {
    useQueryItemTabelaPreco,
    useDesvincularProdutos,
    useUpdatePrecoProdutosVinculados,
  } = useTabelaPreco()
  const {
    data: produtosVinculados,
    isLoading,
    isFetching,
    error,
  } = useQueryItemTabelaPreco(tabelaPrecoUuid, {
    queryParams: appliedValues,
    requestId: searchId,
    pagination,
  })
  const {
    mutateAsync: desvincularProdutos,
    isLoading: isLoadingDesvincularProdutos,
  } = useDesvincularProdutos()
  const {
    mutateAsync: updateProdutosVinculados,
    isLoading: isLoadingUpdatePrecoProdutosVinculados,
  } = useUpdatePrecoProdutosVinculados()

  const { isOpen: isOpenDialog, openDialog, closeDialog } = useDialog()

  const formattedData = useMemo(
    () =>
      produtosVinculados?.data?.map((item) => {
        return {
          ...item,
          codigoProduto: item.produto.codigo,
          nomeProduto: item.produto.nome,
          precoProduto: formatCurrency(item?.preco || ''),
          percentualComissao: formatCurrency(item?.percentualComissao || ''),
          corNome: item?.cor?.nome || '',
          gradeNome: item?.grade?.nome,
        }
      }),
    [produtosVinculados],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigoProduto',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nomeProduto',
        label: 'Nome',
        options: {
          sort: false,
        },
      },
      {
        name: 'corNome',
        label: 'Variação 1',
        options: {
          display:
            configuracoes?.modulo_pedido === 'COR' ||
            configuracoes?.modulo_pedido === 'GRADE',
          sort: false,
        },
      },
      {
        name: 'gradeNome',
        label: 'Variação 2',
        options: {
          display: configuracoes?.modulo_pedido === 'GRADE',
          sort: false,
        },
      },
      {
        name: 'quantidadeMinima',
        label: 'Grade fechada',
        options: {
          setCellProps() {
            return {
              style: {
                textAlign: 'center',
                width: '60px',
              },
            }
          },
          customBodyRender(value) {
            return <Flag isFlagged={value === 1} type="success" />
          },
          sort: false,
        },
      },
      {
        name: 'percentualComissao',
        label: 'Comissão(%)',
        options: {
          setCellProps() {
            return { style: { width: '172px', textAlign: 'right' } }
          },
          customBodyRender: (value, tableMeta) => {
            const rowIndex = tableMeta.rowIndex
            return (
              <InteractiveCell
                rowIndex={rowIndex}
                value={value}
                tabelaPrecoUuid={tabelaPrecoUuid}
                produtos={
                  produtosVinculados?.data || ({} as ItemTabelaPrecoModel[])
                }
                tipoValor="comissao"
                tipoOperacao="vinculado"
              />
            )
          },
        },
      },
      {
        name: 'precoProduto',
        label: 'Preço',
        options: {
          setCellProps() {
            return { style: { width: '172px', textAlign: 'right' } }
          },
          customBodyRender: (value, tableMeta) => {
            const rowIndex = tableMeta.rowIndex
            return (
              <InteractiveCell
                rowIndex={rowIndex}
                value={value}
                tabelaPrecoUuid={tabelaPrecoUuid}
                produtos={
                  produtosVinculados?.data || ({} as ItemTabelaPrecoModel[])
                }
                tipoOperacao="vinculado"
                tipoValor="preco"
              />
            )
          },
        },
      },
    ],
    [produtosVinculados],
  )

  async function handleUpdateProdutosVinculados() {
    const produtosSelecionados: Array<UpdateVinculoProdutosInputProps> =
      indexProdutosSelecionados.map((index) => {
        return {
          uuid: produtosVinculados?.data[index]?.uuid || '',
          produtoUuid: produtosVinculados?.data[index]?.produto?.uuid || '',
          preco: precoGlobal,
        }
      })

    await updateProdutosVinculados({
      item: produtosSelecionados,
      tabelaPrecoUuid,
    })
  }

  async function handleUpdatePercentualComissaoProdutosVinculados() {
    const produtosSelecionados: Array<UpdateVinculoProdutosInputProps> =
      indexProdutosSelecionados.map((index) => {
        return {
          uuid: produtosVinculados?.data[index]?.uuid || '',
          produtoUuid: produtosVinculados?.data[index]?.produto?.uuid || '',
          percentualComissao: comissaoGlobal,
        }
      })

    await updateProdutosVinculados({
      item: produtosSelecionados,
      tabelaPrecoUuid,
    })
  }

  async function handleUpdateQtdMinima() {
    const produtosSelecionados = indexProdutosSelecionados.map((index) => {
      return {
        uuid: produtosVinculados?.data[index].uuid || '',
        produtoUuid: produtosVinculados?.data[index]?.produto?.uuid || '',
        // preco: produtosVinculados?.data[index]?.preco,
        quantidadeMinima: quantidadeMinima,
      }
    })

    await updateProdutosVinculados({
      item: produtosSelecionados,
      tabelaPrecoUuid,
    })

    // setIndexProdutosSelecionados([])
    // setQuantidadeMinima()
  }

  async function handleClickDesvincularProdutos() {
    const produtosSelecionadosIds = indexProdutosSelecionados.map(
      (index) => produtosVinculados?.data[index]?.uuid || '',
    )

    if (tabelaPrecoUuid && temProdutosSelecionados(produtosSelecionadosIds)) {
      try {
        await desvincularProdutos({
          tabelaPrecoUuid,
          produtosSelecionadosIds,
        })
      } finally {
        setIndexProdutosSelecionados([])
        closeDialog()
      }
    }
  }

  return (
    <>
      <DataTable
        error={error?.message}
        isLoading={isLoading}
        isFetching={isFetching}
        columns={columns}
        data={formattedData || []}
        pagination={produtosVinculados?.pagination}
        changePage={changePage}
        changePageSize={changePageSize}
        options={{
          rowsPerPageOptions: [15, 50, 100],
          rowsPerPage: 15,
          selectableRows: 'multiple',
          rowsSelected: indexProdutosSelecionados,
          sort: true,
          onRowSelectionChange: (_, __, rowsSelected: any) =>
            setIndexProdutosSelecionados(rowsSelected),
          customToolbarSelect() {
            return (
              <Stack spacing={1} mr={1}>
                <CheckboxButton
                  buttonProps={{
                    onClick: handleUpdateQtdMinima,
                    label: Boolean(quantidadeMinima)
                      ? 'Fechar Grade'
                      : 'Liberar Grade',
                  }}
                  checked={Boolean(quantidadeMinima)}
                  onChange={(e) => {
                    const qtd = e.target.checked ? 1 : 0
                    setQuantidadeMinima(qtd)
                  }}
                  isLoading={isLoadingUpdatePrecoProdutosVinculados}
                />

                <CurrencyTextButton
                  buttonProps={{
                    onClick: handleUpdateProdutosVinculados,
                    label: 'Aplicar preços',
                  }}
                  value={precoGlobal}
                  onChange={(_, value) => setPrecoGlobal(value)}
                  isLoading={isLoadingUpdatePrecoProdutosVinculados}
                />

                <CurrencyTextButton
                  buttonProps={{
                    onClick: handleUpdatePercentualComissaoProdutosVinculados,
                    label: 'Aplicar comissões',
                  }}
                  value={comissaoGlobal}
                  onChange={(_, value) => setComissaoGlobal(value)}
                  isLoading={isLoadingUpdatePrecoProdutosVinculados}
                />

                <Button color="error" onClick={openDialog}>
                  Desvincular Produtos
                </Button>
              </Stack>
            )
          },
        }}
      />

      <ConfirmationDialog
        isOpen={isOpenDialog}
        isLoading={isLoadingDesvincularProdutos}
        onClose={closeDialog}
        onClick={handleClickDesvincularProdutos}
      />
    </>
  )
}
