import { Box, Typography, useTheme } from '@mui/material'
import { FiberManualRecord } from '@mui/icons-material'
import { AppNotificationsModel } from 'hooks/queries/useAppNotifications'
import { useState } from 'react'
import { ItemOptions } from './components/ItemOptions'

export interface ItemProps {
  notificacao: AppNotificationsModel
  onClick: (notificacao: AppNotificationsModel) => void
}

export default function Item(props: ItemProps) {
  const { notificacao, onClick } = props
  const [mouseIn, setMouseIn] = useState<boolean>(false)

  const theme = useTheme()

  function toSubstring(descricao: string) {
    if (descricao.length > 66) {
      return descricao.substring(0, 66) + '...'
    }

    return descricao
  }

  return (
    <Box
      sx={{
        padding: 1,
        borderRadius: 1,
        ':hover': {
          backgroundColor: '#DEDEDE',
          color: (theme) => theme.palette.primary.dark,
          cursor: 'pointer',
        },
      }}
      onClick={() => {
        onClick(notificacao)
      }}
      onMouseEnter={() => {
        setMouseIn(true)
      }}
      onMouseLeave={() => {
        setMouseIn(false)
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 1.3,
        }}
      >
        <Box>
          <FiberManualRecord
            sx={{
              width: '12px',
              height: '12px',
              color: `${
                notificacao.lido ? 'transparent' : theme.palette.primary.main
              }`,
            }}
          />
        </Box>
        <Box>
          {notificacao.titulo && (
            <Typography variant="subtitle2" fontWeight="bolder" fontSize="10pt">
              {notificacao.titulo}
            </Typography>
          )}
          <Typography
            variant="body1"
            lineHeight="1.4"
            fontSize="11pt"
            color={
              notificacao.lido
                ? theme.palette.grey[500]
                : theme.palette.grey[600]
            }
          >
            {toSubstring(notificacao.descricao)}
          </Typography>
          <Typography
            variant="body2"
            fontWeight="bold"
            fontSize="9pt"
            color={theme.palette.grey[500]}
          >
            {notificacao.timeAsRelative}
          </Typography>
        </Box>
      </Box>

      <ItemOptions mouseIn={mouseIn} notificacao={notificacao} />
    </Box>
  )
}
