import { MenuItem, Stack, TextField } from '@mui/material'

import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'

import DateRangePicker, { DateRange } from 'components/Inputs/DateRangePicker'
import { DateTime } from 'luxon'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import { useUserContext } from 'components/Contexts/UserContext'
import { useEffect } from 'react'
import MixProdutosTransporter from 'components/Transporters/MixProdutosTransporter'
import {
  RelatorioComparacaoPerformanceProdutosFilters,
  RelatorioComparacaoPerformanceProdutosFilters1,
} from 'hooks/queries/useRelatorioComparacaoPerformanceProdutos'

export default function Filter() {
  const { changeFilterValue, onChangeFilterValue } = useFilterApi()
  const { filterValues, isOpen } = useFilterData<
    RelatorioComparacaoPerformanceProdutosFilters,
    RelatorioComparacaoPerformanceProdutosFilters1
  >()
  const { temPermissao, user } = useUserContext()

  const permissaoParaLerNegociacoes =
    temPermissao('negociacoes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerNegociacoes === 'pessoal') {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerNegociacoes, isOpen])

  return (
    <FilterContainer
      disableds={['situacao', 'dateRange', 'mix1', 'mix2', 'tipoDeCalculo']}
      title="Pesquisar Performance de Produtos"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'Vendedor') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Mix Produtos 1') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Mix Produtos 2') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Situação') {
          const value =
            filterValue === 'emissao' ? 'Faturados' : 'Em Processamento'
          return `${filterLabel} - ${value || ''}`
        }

        if (filterLabel === 'Calcular por') {
          const value = filterValue === 'pesoliquido' ? 'Peso' : 'Valor'
          return `${filterLabel} - ${value || ''}`
        }

        if (filterLabel === 'Período') {
          const dateRange = filterValue as DateRange
          const formattedFirstDate = DateTime.fromFormat(
            dateRange?.firstDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          const formattedSecondDate = DateTime.fromFormat(
            dateRange?.secondDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          if (dateRange.tipo === 'day') {
            return `${filterLabel} - ${formattedFirstDate}`
          }

          return `${filterLabel} - de ${formattedFirstDate} até ${formattedSecondDate}`
        }

        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={1.5}>
        <fieldset
          style={{
            border: '1px solid #cfcfcf',
            borderRadius: '4px',
          }}
        >
          <legend
            style={{
              color: '#808080',
              fontSize: '0.7rem',
              paddingLeft: '4px',
              paddingRight: '16px',
            }}
          >
            Período
          </legend>

          <Stack
            sx={{
              gap: 2,
              borderRadius: '4px',
            }}
          >
            <DateRangePicker
              renderFieldSet={false}
              onChange={(value) => {
                changeFilterValue('dateRange', value)
              }}
              value={filterValues?.dateRange?.value}
            />
          </Stack>
        </fieldset>
        <TextField
          select
          label="Situação do Pedido"
          value={filterValues?.situacao?.value || ''}
          onChange={onChangeFilterValue}
          name="situacao"
        >
          <MenuItem value="emissao">Faturados</MenuItem>
          <MenuItem value="venda">Em processamento</MenuItem>
        </TextField>
        <TextField
          select
          label="Calcular por"
          value={filterValues?.tipoDeCalculo?.value || ''}
          name="tipoDeCalculo"
          onChange={onChangeFilterValue}
        >
          <MenuItem value="totalliquido">Valor Total de Venda</MenuItem>
          <MenuItem value="pesoliquido">Peso Total de Venda</MenuItem>
        </TextField>
        <TimeVendasTransporter
          disabled={temPermissao('clientes.read') === 'pessoal'}
          name="representanteUuid"
          label={filterValues?.representante?.label}
          value={filterValues?.representante?.value || null}
          onChange={(representante) => {
            changeFilterValue('representante', representante)
          }}
          InputLabelProps={{ shrink: true }}
        />
        <MixProdutosTransporter
          name="mix1Uuid"
          label={filterValues?.mix1?.label}
          value={filterValues?.mix1?.value || null}
          onChange={(mix1) => {
            changeFilterValue('mix1', mix1)
          }}
          InputLabelProps={{ shrink: true }}
        />
        <MixProdutosTransporter
          name="mix2Uuid"
          label={filterValues?.mix2?.label}
          value={filterValues?.mix2?.value || null}
          onChange={(mix2) => {
            changeFilterValue('mix2', mix2)
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Stack>
    </FilterContainer>
  )
}
