import Surface from 'components/Surface'
import Etapa from '../Etapa'
import useFunilEtapa, { EtapaModel } from 'hooks/queries/useFunilEtapa'
import AddFirst from 'components/Displays/AddFirst'
import { useState } from 'react'
import { LinearProgress } from '@mui/material'
export interface FunilProps {
  funilUuid: string
}
export default function Funil(props: FunilProps) {
  const { funilUuid } = props
  const { useQuery, useSubmit, useDelete, useAtualizarPosicaoDaEtapa } =
    useFunilEtapa(funilUuid)
  const [posicaoDaEtapaCriada, setPosicaoDaEtapaCriada] = useState<
    number | undefined
  >()
  const { data, isLoading, isFetching } = useQuery({
    queryParams: {
      funilUuid,
    },
    pagination: {
      page: 1,
      pageSize: 999,
    },
  })
  const { mutateAsync, isLoading: isSubmitting } = useSubmit()
  const { mutateAsync: excluirEtapa, isLoading: isDeleting } = useDelete()
  const { mutateAsync: alterarPosicaoDaEtapa } = useAtualizarPosicaoDaEtapa()

  async function salvarEtapa(etapa: EtapaModel) {
    try {
      await mutateAsync(etapa)
      setPosicaoDaEtapaCriada(undefined)
    } catch (err) {}
  }

  async function criarNovaEtapa(position: number) {
    const etapa = {
      nome: `Etapa ${position}`,
      posicao: position,
      negociacoes: [],
    }
    try {
      await mutateAsync(etapa)
      setPosicaoDaEtapaCriada(position)
    } catch (err) {}
  }

  function handleOnAdd(position: number) {
    criarNovaEtapa(position)
  }

  function handleOnDelete(etapa: EtapaModel) {
    excluirEtapa(etapa)
  }

  function handleChangePosition(etapa: EtapaModel, direction: 'up' | 'down') {
    alterarPosicaoDaEtapa({ etapaUuid: etapa.uuid || '', funilUuid, direction })
  }

  const fator = 75
  const paletaFunilVendas = [
    '#add8e6', // Azul Claro
    '#89cff0', // Azul Bebê
    '#87cefa', // Azul Celeste
    '#40e0d0', // Azul Turquesa
    '#1e90ff', // Azul Denim
    '#191970', // Azul Meia-Noite
    '#4169e1', // Azul Royal
    '#2e8b57', // Azul Petróleo
    '#6a5acd', // Azul Ardósia
    '#000080', // Azul Marinho
  ]

  function getColor(i: number) {
    return paletaFunilVendas[i]
    // const qtdTotalEtapas = data?.data.length || 0
    // const distribuicao = 12 / qtdTotalEtapas
    // const indexDaCor = Math.round(i * distribuicao)
    // return paletaFunilVendas[indexDaCor]
  }

  const hasEtapas = Array.isArray(data?.data) && data?.data?.length > 0
  return (
    <>
      {(isLoading || isFetching) && <LinearProgress />}
      {!hasEtapas && (
        <AddFirst
          isLoading={isSubmitting}
          buttonLabel="Criar etapa"
          primaryMessage="Este funil ainda não tem as etapadas definidas"
          secondaryMessage={
            <div style={{ textAlign: 'center' }}>
              Criar as etapas do seu funil de vendas é como desenhar o mapa do
              sucesso para o seu negócio.
              <br />
              Cada etapa representa uma oportunidade de conquista, um passo mais
              próximo de alcançar seus objetivos.
            </div>
          }
          onClick={() => {
            criarNovaEtapa(1)
          }}
        />
      )}

      {hasEtapas && (
        <Surface>
          {data?.data.map((e, i) => {
            return (
              <Etapa
                hideUp={i === 0}
                hideDown={i === data?.data.length - 1}
                isNew={e.posicao === posicaoDaEtapaCriada}
                isDeleting={isDeleting}
                key={e.uuid}
                onAdd={handleOnAdd}
                onDelete={handleOnDelete}
                onChange={(etapa) => salvarEtapa(etapa)}
                onChangePosition={handleChangePosition}
                etapa={e}
                containerProps={{
                  position: 'relative',
                  margin: `0 ${fator * i}px`,
                  borderLeft: `${fator}px solid transparent`,
                  borderRight: `${fator}px solid transparent`,
                  borderTop: `100px solid ${getColor(i)}`,
                  height: '100px',
                  borderRadius: '20px',
                  mt: 0.5,
                }}
              />
            )
          })}
        </Surface>
      )}
    </>
  )
}
