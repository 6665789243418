import { useEffect, useMemo, useState } from 'react'

import { Badge, IconButton, Popover, Box, Stack, useTheme } from '@mui/material'
import {
  DoneAll,
  Notifications as NotificationsIcon,
} from '@mui/icons-material'

import { Button } from '@data-c/ui'
import { usePagination } from '@data-c/hooks'

import useAppNotifications, {
  AppNotificationsModel,
  EntidadesEnum,
} from 'hooks/queries/useAppNotifications'
import { useUserContext } from 'components/Contexts/UserContext'
import usePopOver from 'hooks/usePopover'
import socket from 'services/ws'
import Item from './components/Item'
import Header from './components/Header'
import EmptyNotifications from './components/Item/components/EmptyNotifications'
import LoadingNotifications from './components/Item/components/LoadingNotifications'
import { useNavigate } from 'react-router-dom'
import queryClient from 'services/query-client'

export default function Notifications() {
  const { isOpen, anchorEl, open, close } = usePopOver()
  const navigate = useNavigate()
  const {
    useMarcarTodasNotificacoesComoLidas,
    useUpdateNotificationStatus,
    useObterNotificacoesPorDestinatario,
  } = useAppNotifications()
  const { pagination } = usePagination()
  const { user } = useUserContext()
  const theme = useTheme()
  const [notificationStatus, setNotificationStatus] = useState<
    'todas' | 'naoLidas'
  >('todas')

  const { data, isFetching } = useObterNotificacoesPorDestinatario(
    user?.uuid || '',
    {
      pagination,
      queryParams: { situacao: notificationStatus },
    },
  )

  useEffect(() => {
    function novaNotificacao(event: any) {
      queryClient.invalidateQueries(['NOTIFICACOES'])
    }

    socket.on('new:notification', novaNotificacao)

    return () => {
      socket.off('new:notification', novaNotificacao)
    }
  }, [user?.uuid])

  const { mutateAsync: updateNotificationStatus } =
    useUpdateNotificationStatus()

  const { mutateAsync: marcarTodasComoLida } =
    useMarcarTodasNotificacoesComoLidas()

  const notifications =
    data?.data?.map((n) => {
      return { ...n }
    }) || []

  const quantidadeNaoLida = useMemo(() => {
    return notifications.reduce((acumulador, valorAtual) => {
      if (!valorAtual.lido) {
        return acumulador + 1
      }
      return acumulador
    }, 0)
  }, [notifications])

  function handleClick(event: 'todas' | 'naoLidas') {
    setNotificationStatus(event)
  }

  function handleMarcarTodasNotificacoesComoLidas() {
    marcarTodasComoLida()
  }

  function handleNotificationClick(notification: AppNotificationsModel) {
    updateNotificationStatus({ ...notification, lido: true })
    if (notification.entidade === EntidadesEnum.COTACAO) {
      navigate(`/pedidos/pedido/${notification.entidadeUuid}`)
    }
    if (notification.entidade === EntidadesEnum.ATIVIDADE) {
      navigate(`/crm/atividades?id=${notification.entidadeUuid}`)
    }
    if (notification.entidade === EntidadesEnum.COMENTARIO_ATIVIDADE) {
      navigate(
        `/crm/atividades?id=${notification.entidadeUuid}&tab=comentarios`,
      )
    }
    if (notification.entidade === EntidadesEnum.COMENTARIO_PEDIDO) {
      navigate(`/pedidos/pedido/${notification.entidadeUuid}?tab=comentarios`)
    }
    close()
  }

  return (
    <Box>
      <Badge
        badgeContent={quantidadeNaoLida}
        color="error"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <IconButton size="small" onClick={open}>
          <NotificationsIcon
            sx={{
              color: theme.palette.grey[100],
            }}
            fontSize="medium"
          />
        </IconButton>
      </Badge>
      <Popover
        open={isOpen}
        onClose={close}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Header
          notificationStatus={notificationStatus}
          onClick={handleClick}
          isFetching={isFetching}
        />
        <Stack padding={1} width="300px" minHeight="150px">
          <LoadingNotifications
            isFetching={Boolean(isFetching)}
            notifications={notifications}
          />
          <EmptyNotifications
            isFetching={Boolean(isFetching)}
            notifications={notifications}
          />
          {notifications.map((n) => {
            return <Item notificacao={n} onClick={handleNotificationClick} />
          })}

          {notifications.length > 0 && (
            <Button
              variant="text"
              onClick={handleMarcarTodasNotificacoesComoLidas}
              sx={{ gap: 1 }}
            >
              <DoneAll />
              Marcar todas como lidas
            </Button>
          )}
        </Stack>
      </Popover>
    </Box>
  )
}
