import { Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'
import ProdutosVisibilidade from '../ProdutosVisibilidade'

export default function CadastrosVisibilidade() {
  const { role, changeRolePermission } = useRoleContext()
  const {
    cadastros,
    clientes,
    vendedores,
    tabela_precos,
    condicao_pagamento,
    forma_pagamento,
    fator_precificacao,
    mix_produtos,
    // importar_dados,
  } = role?.permissoes || {}

  return (
    <CardConfig
      title="Cadastros"
      description="Define se o usuário tem permissão para acessar o menu Cadastros"
      collapse={Boolean(cadastros?.visibilidade)}
      component={
        <Switch
          name="cadastros.visibilidade"
          checked={Boolean(cadastros?.visibilidade)}
          onChange={changeRolePermission}
          disabled={Boolean(role?.fixa)}
        />
      }
    >
      <CardConfig
        title="Clientes"
        disabledPadding
        description="Define se o usuário tem permissão para acessar o menu Clientes"
        component={
          <Switch
            name="clientes.visibilidade"
            checked={Boolean(clientes?.visibilidade)}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />

      <CardConfig
        title="Vendedores"
        description="Define se o usuário tem permissão para acessar o menu Vendedores"
        disabledPadding
        component={
          <Switch
            name="vendedores.visibilidade"
            checked={Boolean(vendedores?.visibilidade)}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />

      <CardConfig
        title="Tabela de Preços"
        description="Define se o usuário tem permissão para acessar as Tabelas de Preços"
        disabledPadding
        component={
          <Switch
            name="tabela_precos.visibilidade"
            checked={Boolean(tabela_precos?.visibilidade)}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />

      <CardConfig
        title="Condição de Pagamento"
        description="Define se o usuário tem permissão para acessar o menu Condicao de Pagamento"
        disabledPadding
        component={
          <Switch
            name="condicao_pagamento.visibilidade"
            checked={Boolean(condicao_pagamento?.visibilidade)}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />

      <CardConfig
        title="Forma de Pagamento"
        description="Define se o usuário tem permissão para acessar o menu Forma de Pagamento"
        disabledPadding
        component={
          <Switch
            name="forma_pagamento.visibilidade"
            checked={Boolean(forma_pagamento?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      />

      <CardConfig
        title="Fator de Precificação"
        description="Define se o usuário tem permissão para acessar o menu Fator Precificação"
        disabledPadding
        component={
          <Switch
            name="fator_precificacao.visibilidade"
            checked={Boolean(fator_precificacao?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      />
      <CardConfig
        title="Mix Produtos"
        description="Define se o usuário tem permissão para acessar o menu Mix Produtos"
        disabledPadding
        component={
          <Switch
            name="mix_produtos.visibilidade"
            checked={Boolean(mix_produtos?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      />

      <ProdutosVisibilidade />
    </CardConfig>
  )
}
