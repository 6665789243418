import { QueryClientProvider } from '@tanstack/react-query'

import CssBaseline from '@mui/material/CssBaseline'

import { ToastContainer } from 'react-toastify'

import AppRoutes from './routes'
import queryClient from './services/query-client'
import 'react-toastify/dist/ReactToastify.css'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import 'moment/locale/pt-br'
import UserProvider from 'components/Contexts/UserContext'
import { BrowserRouter } from 'react-router-dom'
import 'styles/app.css'
// import { DateTime } from 'luxon'
import theme from 'styles/theme'
import { ThemeProvider } from '@mui/material/styles'

class CustomAdapterLuxon extends AdapterLuxon {
  // startOfWeek = (value: DateTime) => {
  //   return value.startOf('week').minus({ days: 1 })
  // }
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider
          dateAdapter={CustomAdapterLuxon}
          adapterLocale="pt-br"
        >
          <UserProvider>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </UserProvider>
        </LocalizationProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
      <ToastContainer autoClose={3000} position="bottom-right" />
    </ThemeProvider>
  )
}

export default App
