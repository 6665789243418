import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'
import { MenuItem, Stack, TextField, useTheme } from '@mui/material'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import LinhaProdutoTransporter from 'components/Transporters/LinhaProdutoTransporter'
import GrupoProdutoTransporter from 'components/Transporters/GrupoProdutoTransporter'
import FamiliaProdutoTransporter from 'components/Transporters/FamiliaProdutoTransporter'
import ClasseProdutoTransporter from 'components/Transporters/ClasseProdutoTransporter'

import {
  PositivacaoClientesFilters,
  PositivacaoClientesFilters1,
} from 'hooks/queries/usePositivacaoClientes'
import moment from 'moment'
import { ClasseProdutosModel } from 'hooks/queries/useClasseProdutos'
import { FamiliaProdutosModel } from 'hooks/queries/useFamiliaProdutos'
import { GrupoProdutosModel } from 'hooks/queries/useGrupoProdutos'
import { LinhaProdutosModel } from 'hooks/queries/useLinhaProdutos'
import { useUserContext } from 'components/Contexts/UserContext'
import { useEffect } from 'react'
import { onlyNumbers, plurilize } from '@data-c/hooks'
import DatePicker from 'components/DatePicker'

export default function Filter() {
  const { changeFilterValue } = useFilterApi()
  const theme = useTheme()
  const { temPermissao, user } = useUserContext()
  const { filterValues, isOpen } = useFilterData<
    PositivacaoClientesFilters,
    PositivacaoClientesFilters1
  >()

  const permissaoParaLerNegociacoes =
    temPermissao('negociacoes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerNegociacoes === 'pessoal') {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerNegociacoes, isOpen])
  const pInicial = (filterValues?.periodoInicial?.value || 0) * 1
  const aInicial = (filterValues?.periodoInicialPositivacao?.value || 0) * 1
  return (
    <FilterContainer
      disableds={[
        'situacaoPedido',
        'anoMes',
        'periodoInicialPositivacao',
        'periodoInicial',
      ]}
      title=""
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'Situação') {
          return `Situação - ${
            filterValue === 'emissao' ? 'Faturados' : 'Em Processamento'
          }`
        }

        if (filterLabel === 'Vendedor') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Categoria') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Tipo de Categoria') {
          return `${filterLabel} - ${
            (filterValue as string).toUpperCase() || ''
          }`
        }

        if (filterLabel === 'Período') {
          return `Data - ${moment(filterValue || '').format('MM/YYYY')}`
        }

        if (filterLabel === 'Ativacao') {
          return `Período vendas - ${plurilize('mes', filterValue, true, 'es')}`
        }

        if (filterLabel === 'Positivacao') {
          return `Período ativação - ${plurilize(
            'mes',
            filterValue,
            true,
            'es',
          )}`
        }

        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={1.5}>
        <TextField
          select
          name="situacaoPedido"
          label="Situação"
          value={filterValues?.situacaoPedido?.value || ''}
          onChange={(e) => {
            const situacaoPedido = e.target.value
            changeFilterValue('situacaoPedido', situacaoPedido)
          }}
        >
          <MenuItem value="emissao">Faturados</MenuItem>
          <MenuItem value="venda">Em processamento</MenuItem>
        </TextField>

        <DatePicker
          openTo="month"
          views={['year', 'month']}
          format="MM/yyyy"
          label={'Data Base'}
          onChange={(date: string | null) => {
            changeFilterValue('anoMes', date)
          }}
          value={filterValues?.anoMes?.value || ''}
          // fromFormat="yyyy-MM"
        />

        <TextField
          type="number"
          name="periodoInicial"
          label={`Considerar as vendas ${plurilize('do', pInicial)} ${plurilize(
            'último',
            pInicial,
          )} ${plurilize('mes', pInicial, true, 'es')}`}
          value={filterValues?.periodoInicial?.value || ''}
          onChange={(e) => {
            const periodoInicial = onlyNumbers(e.target.value, false)
            changeFilterValue('periodoInicial', periodoInicial)
          }}
        />

        <TextField
          type="number"
          name="periodoInicialPositivacao"
          label={`Considerar clientes ativados com compra ${plurilize(
            'no',
            aInicial,
          )} ${plurilize('último', aInicial)} ${plurilize(
            'mes',
            aInicial,
            true,
            'es',
          )}`}
          value={filterValues?.periodoInicialPositivacao?.value || ''}
          onChange={(e) => {
            const periodoInicialPositivacao = onlyNumbers(e.target.value, false)
            changeFilterValue(
              'periodoInicialPositivacao',
              periodoInicialPositivacao,
            )
          }}
        />

        <TimeVendasTransporter
          disabled={temPermissao('clientes.read') === 'pessoal'}
          name="representanteUuid"
          label={filterValues?.representante?.label}
          value={filterValues?.representante?.value || null}
          onChange={(representante) => {
            changeFilterValue('representante', representante)
          }}
          InputLabelProps={{ shrink: true }}
        />

        <Stack
          sx={{
            gap: 2,
            border: `solid 1px ${theme.palette.primary.main}`,
            padding: 2,
            borderRadius: '4px',
          }}
        >
          <TextField
            select
            name="tipoCategoria"
            label={filterValues?.categoriaKey?.label}
            value={filterValues?.categoriaKey?.value || 'nenhuma'}
            onChange={(e) => {
              const key = e.target.value
              changeFilterValue('categoriaKey', key)
            }}
          >
            <MenuItem value="nenhuma">Todas</MenuItem>
            <MenuItem value="classe">Classe</MenuItem>
            <MenuItem value="familia">Família</MenuItem>
            <MenuItem value="grupo">Grupo</MenuItem>
            <MenuItem value="linha">Linha</MenuItem>
          </TextField>

          {filterValues?.categoriaKey?.value === 'classe' && (
            <ClasseProdutoTransporter
              value={filterValues?.categoriaValue?.value || null}
              onChange={(value: ClasseProdutosModel | null) => {
                changeFilterValue('categoriaValue', value)
              }}
            />
          )}

          {filterValues?.categoriaKey?.value === 'familia' && (
            <FamiliaProdutoTransporter
              value={filterValues?.categoriaValue?.value || null}
              onChange={(value: FamiliaProdutosModel | null) => {
                changeFilterValue('categoriaValue', value)
              }}
            />
          )}

          {filterValues?.categoriaKey?.value === 'grupo' && (
            <GrupoProdutoTransporter
              value={filterValues?.categoriaValue?.value || null}
              onChange={(value: GrupoProdutosModel | null) => {
                changeFilterValue('categoriaValue', value)
              }}
            />
          )}

          {filterValues?.categoriaKey?.value === 'linha' && (
            <LinhaProdutoTransporter
              value={filterValues?.categoriaValue?.value || null}
              onChange={(value: LinhaProdutosModel | null) => {
                changeFilterValue('categoriaValue', value)
              }}
            />
          )}
        </Stack>
      </Stack>
    </FilterContainer>
  )
}
