import { useEffect, useMemo, useRef, useState } from 'react'

import { Box, Grid } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'
import { formatCurrency, isValidNumber, onlyNumbers } from '@data-c/hooks'

import {
  Button,
  ButtonContainer,
  Checkbox,
  CurrencyTextField,
  FormContainer,
  LabelValue,
} from '@data-c/ui'

import {
  ItemPedidoModel,
  PedidoModel,
  SituacaoPedidoEnum,
  useSubmitItemPedido,
} from 'hooks/queries/usePedidos'
import { useCalcularItemPedido } from 'hooks/business/pedido'
import { ItemTabelaPrecoModel } from 'hooks/queries/useTabelaPreco'
import useValidations from 'hooks/useValidations'

import ItensTabelaPrecoTransporter from 'components/Transporters/ItensTabelaPrecoTransporter'
import * as yup from 'yup'
import FloatDrawer from 'components/FloatDrawer'
import { UsuarioModel } from 'hooks/queries/useUsuario'
import { ConfiguracoesModel } from 'hooks/queries/useConfiguracoes'
import TextField from 'components/Inputs/TextField'
import IndicadorLimiteCredito from './components/IndicadorLimiteCredito'
import { IndicadoresLimiteCreditoModel } from 'hooks/queries/useClientes'

export interface FormProps {
  pedido: PedidoModel
  usuarioLogado: UsuarioModel
  onAfterSubmit: (item: ItemPedidoModel) => void
  configuracoes?: ConfiguracoesModel
}

const transform = (_: any, val: any) =>
  isValidNumber(val) ? Number(val) : null

const schema = yup.object().shape({
  quantidade: yup
    .number()
    .nullable(true)
    .required('Informe a quantidade')
    .moreThan(0, 'A Quantidade deve ser superior a zero(0)')
    .transform(transform),
  precoUnitarioLiquido: yup
    .number()
    .nullable(true)
    .required('Informe o Preço de Venda')
    .moreThan(0, 'O Preço de Venda deve ser maior do que R$0,00')
    .transform(transform),
  percentualIpi: yup
    .number()
    .nullable(true)
    .required('Informe o IPI (%)')
    .transform(transform),
  precoUnitarioIpi: yup
    .number()
    .nullable(true)
    .required('Informe o IPI (R$)')
    .transform(transform),
})

export default function Form(props: FormProps) {
  const { pedido, usuarioLogado, configuracoes } = props
  const {
    changeValue,
    changeValues,
    closeForm,
    toggleSubmit,
    onChangeFormValue,
  } = useFormApi<ItemPedidoModel>()
  const {
    formValues: data,
    reason,
    isOpen,
    isOpenConfirm,
  } = useFormData<ItemPedidoModel>()
  const { setValidationErrors, validationProps } = useValidations()
  const { mutateAsync, isLoading } = useSubmitItemPedido()

  const [isFocused, setFocused] = useState(false)
  const [itemTabelaPreco, setItemTabelaPreco] =
    useState<ItemTabelaPrecoModel | null>(null)

  useEffect(() => {
    changeValue('pedidoUuid', pedido?.uuid || null)
  }, [pedido?.uuid, changeValue])

  useEffect(() => {
    if (!isOpen && !isOpenConfirm) {
      setItemTabelaPreco(null)
      changeValues({} as ItemPedidoModel)
    }
  }, [isOpen, isOpenConfirm])
  const itemPedidoCalculado = useCalcularItemPedido(data, reason)
  const itensTabelaPrecoRef = useRef<HTMLInputElement>(null)
  const bottomRef = useRef<HTMLInputElement>(null)

  const calulcarImpostos =
    pedido.ajuste === 100 && configuracoes?.usa_calculo_imposto

  function handleChangeProduto(_itemTabelaPreco: ItemTabelaPrecoModel | null) {
    const produto = _itemTabelaPreco?.produto

    if (produto) {
      let precoUnitarioIpi = 0
      if (calulcarImpostos) {
        precoUnitarioIpi = _itemTabelaPreco?.valorUnitarioIpi || 0
      } else {
        _itemTabelaPreco.valorUnitarioIpi = 0
      }

      setItemTabelaPreco(_itemTabelaPreco)

      changeValues(
        {
          ...data,
          produto: produto || null,
          produtoUuid: produto?.uuid || null,
          precoDeTabela: _itemTabelaPreco?.preco || 0,
          quantidade: 1,
          precoUnitarioIpi: precoUnitarioIpi,
          precoUnitarioLiquido: _itemTabelaPreco?.preco || 0,
          percentualDesconto: 0,
          // pedidoUuid: pedido.uuid || null,
        },
        'precoUnitarioIpi',
      )
    }
  }

  const ehNovoItem = useMemo(() => {
    if (Boolean(data?.uuid)) {
      setItemTabelaPreco(null)
      return false
    }

    if (Boolean(itemTabelaPreco?.produto.uuid)) return true

    changeValues({} as ItemPedidoModel)
    return false
  }, [itemTabelaPreco, data?.uuid])

  const podeEditar = useMemo(() => {
    const situacao = pedido.situacao

    return (
      situacao === SituacaoPedidoEnum.WEB ||
      situacao === SituacaoPedidoEnum.MOBILE ||
      situacao === SituacaoPedidoEnum.COTACAO_SOLICITACAO ||
      situacao === SituacaoPedidoEnum.COTACAO_ANALISE
    )
  }, [pedido])

  function toCollapse() {
    if (ehNovoItem) return true
    return ehNovoItem
  }

  function handleCloseForm() {
    setItemTabelaPreco(null)
    closeForm()
  }

  function handleFocus() {
    setFocused(!isFocused)
  }

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(itemPedidoCalculado, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)

        await mutateAsync({
          pedidoUuid: pedido.uuid || '',
          item: itemPedidoCalculado,
        })
        closeForm()
        setItemTabelaPreco(null)
        if (itensTabelaPrecoRef) {
          itensTabelaPrecoRef?.current?.focus()
        }
        if (bottomRef) {
          bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  const indicadoresLimiteCredito =
    pedido.cliente?.indicadoresLimiteCredito ||
    ({
      limiteDeCredito: 0,
      valorDuplicatasEmAberto: 0,
      valorPedidosFechadosEAnalise: 0,
    } as IndicadoresLimiteCreditoModel)

  //Valor é 0 se a condição de pagamento for à vista
  const totalPedidoAtual =
    parseInt(onlyNumbers(pedido?.condicaoPagamento?.condpgtot || '0') || '0') >
    0
      ? pedido.totalLiquido
      : 0

  const limiteRestante =
    indicadoresLimiteCredito.limiteDeCredito -
    (indicadoresLimiteCredito.valorDuplicatasEmAberto +
      indicadoresLimiteCredito.valorPedidosFechadosEAnalise +
      totalPedidoAtual)

  return (
    <Box>
      <Box sx={{ height: toCollapse() ? '369px' : '86px' }} ref={bottomRef} />
      <FloatDrawer
        open={toCollapse()}
        drawerHeader={
          <Box sx={{ display: 'flex', gap: '70px', alignItems: 'center' }}>
            {configuracoes?.usa_limite_credito && (
              <LabelValue
                label={`Limite de Crédito: ${formatCurrency(limiteRestante)}`}
                isNumber
                contentProps={{ variant: 'h6', fontWeight: 'bold' }}
                labelProps={{ variant: 'body2' }}
              >
                <IndicadorLimiteCredito
                  data={{
                    ...indicadoresLimiteCredito,
                    valorPedidoAtual: totalPedidoAtual,
                  }}
                />
              </LabelValue>
            )}
            <LabelValue
              label="Total Pedido"
              isNumber
              contentProps={{ variant: 'h6', fontWeight: 'bold' }}
              labelProps={{ variant: 'body2' }}
            >
              {formatCurrency(pedido.totalComImposto)}
            </LabelValue>
          </Box>
        }
        action={
          <Box>
            {podeEditar && (
              <ItensTabelaPrecoTransporter
                value={itemTabelaPreco}
                tabelaPrecoUuid={pedido.tabelaPreco?.uuid || ''}
                label={isFocused ? 'Produto' : ''}
                placeholder="Digite o nome ou o código do produto para adicioná-lo ao pedido"
                onChange={(value) => handleChangeProduto(value)}
                onFocus={handleFocus}
                onBlur={handleFocus}
                inputRef={itensTabelaPrecoRef}
              />
            )}
          </Box>
        }
      >
        <FormContainer
          dialogProps={{ title: 'Item do Pedido', maxWidth: 'md' }}
          triggerButton="none"
          view={ehNovoItem ? 'plain' : 'dialog'}
          onSubmitForm={handleSubmitForm}
          actions={
            <ButtonContainer>
              <Button color="error" onClick={handleCloseForm}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                isLoading={isLoading}
                onClick={handleSubmitForm}
              >
                Salvar
              </Button>
            </ButtonContainer>
          }
        >
          {!ehNovoItem && (
            <LabelValue label="Produto">
              {`${
                data.produto?.uuid
                  ? `${data.produto?.codigo} - ${data.produto?.nome}`
                  : 'Nenhum produto selecionado'
              }`}
            </LabelValue>
          )}
          <Grid paddingTop={2} container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
              {data?.produto?.fracionado ? (
                <CurrencyTextField
                  name="quantidade"
                  label="Quantidade"
                  required
                  autoFocus
                  value={itemPedidoCalculado?.quantidade || ''}
                  onChange={onChangeFormValue}
                  inputProps={{ maxLength: 40 }}
                  {...validationProps('quantidade')}
                />
              ) : (
                <TextField
                  name="quantidade"
                  label="Quantidade"
                  required
                  autoFocus
                  value={itemPedidoCalculado?.quantidade || ''}
                  onChange={(e) => {
                    const quantidade = onlyNumbers(e.target.value, false)
                    changeValue('quantidade', quantidade)
                  }}
                  inputProps={{ maxLength: 40 }}
                  {...validationProps('quantidade')}
                />
              )}
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
              <CurrencyTextField
                name="precoDeTabela"
                label="Preço de Tabela (R$)"
                required
                value={itemPedidoCalculado?.precoDeTabela || ''}
                disabled
              />
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
              <CurrencyTextField
                name="precoDeTabela"
                label="Preço de Tabela (R$)"
                required
                value={itemPedidoCalculado?.precoDeTabela || ''}
                disabled
              />
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
              <CurrencyTextField
                name="percentualDesconto"
                label="Desconto (%)"
                value={itemPedidoCalculado?.percentualDesconto || ''}
                onChange={(_, value) => {
                  changeValue('percentualDesconto', value, 'percentualDesconto')
                }}
              />
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
              <CurrencyTextField
                name="precoUnitarioLiquido"
                label="Preço de Venda (R$)"
                required
                value={itemPedidoCalculado?.precoUnitarioLiquido || ''}
                onChange={(_, value) =>
                  changeValue(
                    'precoUnitarioLiquido',
                    value,
                    'precoUnitarioLiquido',
                  )
                }
                {...validationProps('precoUnitarioLiquido')}
              />
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
              <CurrencyTextField
                name="precoTotalLiquido"
                label="Preço Total (R$)"
                disabled
                value={itemPedidoCalculado?.precoTotalLiquido || ''}
                // {...validationProps('nome')}
              />
            </Grid>
          </Grid>

          {configuracoes?.usa_calculo_imposto && (
            <Grid
              sx={{ pt: 2 }}
              container
              spacing={2}
              justifyContent="flex-end"
            >
              <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                <CurrencyTextField
                  name="percentualIpi"
                  label="IPI (%)"
                  value={itemPedidoCalculado?.percentualIpi || ''}
                  onChange={(_, value) => {
                    changeValue('percentualIpi', value, 'percentualIpi')
                  }}
                  {...validationProps('percentualIpi')}
                  disabled={!calulcarImpostos || !usuarioLogado.isadmin}
                />
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                <CurrencyTextField
                  name="precoUnitarioIpi"
                  label="Preço Uni. IPI (R$)"
                  value={itemPedidoCalculado?.precoUnitarioIpi || ''}
                  onChange={(_, value) =>
                    changeValue('precoUnitarioIpi', value, 'precoUnitarioIpi')
                  }
                  {...validationProps('precoUnitarioIpi')}
                  disabled={!calulcarImpostos || !usuarioLogado.isadmin}
                />
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                <CurrencyTextField
                  disabled
                  name="precoTotalIpi"
                  label="Preço Total IPI (R$)"
                  value={itemPedidoCalculado?.precoTotalIpi || ''}
                />
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
                <CurrencyTextField
                  disabled
                  name="precoTotalComImposto"
                  label="Total Nota (R$)"
                  value={itemPedidoCalculado?.precoTotalComImposto || ''}
                  inputProps={{
                    style: {
                      textAlign: 'right',
                      fontWeight: 'bold',
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Grid sx={{ pt: 2 }} container spacing={2} justifyContent="flex-end">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                name="observacao"
                label="Observação"
                value={itemPedidoCalculado?.observacao || ''}
                multiline
                rows={4}
                onChange={onChangeFormValue}
              />
            </Grid>
            {usuarioLogado.isadmin && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Checkbox
                  label="Bloqueado"
                  name="itemBloqueado"
                  checked={Boolean(itemPedidoCalculado?.itemBloqueado)}
                  onChange={onChangeFormValue}
                />
              </Grid>
            )}
          </Grid>
        </FormContainer>
      </FloatDrawer>
    </Box>
  )
}
