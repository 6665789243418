import { Stack } from '@mui/material'

import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'

import {
  TaxaConversaoNegociacaoModelFilters,
  TaxaConversaoNegociacaoModelFilters1,
} from 'hooks/queries/useRelatorioTaxaConversaoNegociacao'
import DateRangePicker, { DateRange } from 'components/Inputs/DateRangePicker'
import { DateTime } from 'luxon'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import { useUserContext } from 'components/Contexts/UserContext'
import { useEffect } from 'react'
import DropDownFunilVendas from 'components/Inputs/DropDownFunilVendas'

export default function Filter() {
  const { changeFilterValue } = useFilterApi()
  const { filterValues, isOpen } = useFilterData<
    TaxaConversaoNegociacaoModelFilters,
    TaxaConversaoNegociacaoModelFilters1
  >()
  const { temPermissao, user } = useUserContext()

  const permissaoParaLerNegociacoes =
    temPermissao('negociacoes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerNegociacoes === 'pessoal') {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerNegociacoes, isOpen])

  return (
    <FilterContainer
      disableds={['funilVenda']}
      title="Gerar Relatório"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'Vendedor') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Funil de Venda') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Período') {
          const dateRange = filterValue as DateRange
          const formattedFirstDate = DateTime.fromFormat(
            dateRange?.firstDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          const formattedSecondDate = DateTime.fromFormat(
            dateRange?.secondDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          if (dateRange.tipo === 'day') {
            return `${filterLabel} - ${formattedFirstDate}`
          }

          return `${filterLabel} - de ${formattedFirstDate} até ${formattedSecondDate}`
        }

        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={2}>
        <DateRangePicker
          onChange={(value) => {
            changeFilterValue('dateRange', value)
          }}
          value={filterValues?.dateRange?.value}
        />

        <DropDownFunilVendas
          label="Funil de Vendas"
          name="funilUuid"
          value={filterValues?.funilVenda?.value?.uuid || ''}
          onChange={(funil) => {
            changeFilterValue('funilVenda', funil)
          }}
        />

        <TimeVendasTransporter
          disabled={temPermissao('clientes.read') === 'pessoal'}
          name="representanteUuid"
          label={filterValues?.representante?.label}
          value={filterValues?.representante?.value || null}
          onChange={(representante) => {
            changeFilterValue('representante', representante)
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Stack>
    </FilterContainer>
  )
}
