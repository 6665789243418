import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import useFatorPrecificacao from 'hooks/queries/useFatorPrecificacao'

export default function DropDownFatorPromocional(props: TextFieldProps) {
  const { useQuery } = useFatorPrecificacao()
  const { data } = useQuery({
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  return (
    <TextField select {...props}>
      <MenuItem value={-1}>Nenhum</MenuItem>
      {data?.data?.map((item) => {
        return (
          <MenuItem key={item.uuid} value={item?.uuid}>
            {item?.descricao}
          </MenuItem>
        )
      })}
    </TextField>
  )
}
