import { useCallback, useEffect, useMemo, useState } from 'react'

import { Box, Checkbox, CircularProgress } from '@mui/material'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { useFormApi } from '@data-c/providers'
import { findData, formatCurrency, useDialog } from '@data-c/hooks'
import { DataTable, CurrencyCellStyle, DataTableOptions } from '@data-c/ui'

import {
  ItemPedidoModel,
  ItensPedidoSelecionados,
  PedidoModel,
  SituacaoPedidoEnum,
  useUpdateBloqueioItemPedido,
} from 'hooks/queries/usePedidos'
import { calcularItemPedido } from 'hooks/business/pedido'
import { UsuarioModel } from 'hooks/queries/useUsuario'
import PerderItemForm from '../PerderItemForm'
import { ConfiguracoesModel } from 'hooks/queries/useConfiguracoes'
import { useUserContext } from 'components/Contexts/UserContext'

export interface ItensPedidoProps {
  pedido: PedidoModel
  usuarioLogado: UsuarioModel
  isLoading?: boolean
  isFetching?: boolean
  configuracoes?: ConfiguracoesModel
}

export default function ItensPedido(props: ItensPedidoProps) {
  const { pedido, usuarioLogado, configuracoes } = props

  const { openForm, openConfirm } = useFormApi()
  const {
    isOpen,
    openDialog,
    closeDialog,
    data: itemPerdidoSelecionado,
  } = useDialog<ItemPedidoModel>()
  const { temPermissao } = useUserContext()
  const [itensPedidoSelecionados, setItensPedidoSelecionados] = useState<
    ItensPedidoSelecionados[]
  >([])
  const podeEditarPedido = temPermissao('negociacoes.alter') != 'sem_acesso'
  const { mutateAsync } = useUpdateBloqueioItemPedido()

  const podeEditar = useMemo(() => {
    const situacao = pedido.situacao

    return (
      (situacao === SituacaoPedidoEnum.WEB ||
        situacao === SituacaoPedidoEnum.MOBILE ||
        situacao === SituacaoPedidoEnum.COTACAO_SOLICITACAO ||
        situacao === SituacaoPedidoEnum.COTACAO_ANALISE ||
        situacao === SituacaoPedidoEnum.COTACAO_APROVADA) &&
      podeEditarPedido
    )
  }, [pedido, podeEditarPedido])

  const _itens = useMemo(
    () =>
      pedido.itens
        ? pedido.itens.map((item) => {
            const itemCalculado = calcularItemPedido(item, 'precoUnitarioIpi')
            return {
              ...itemCalculado,
              codigoProduto: itemCalculado.produto?.codigo,
              nomeProduto: itemCalculado.produto?.nome,
              precoTotalLiquido: formatCurrency(
                itemCalculado.precoTotalLiquido,
              ),
              precoUnitarioLiquido: formatCurrency(
                itemCalculado.precoUnitarioLiquido,
              ),
              precoDeTabela: formatCurrency(itemCalculado.precoDeTabela),
              precoTotalIcms: formatCurrency(itemCalculado.precoTotalIcms),
              precoTotalIpi: formatCurrency(itemCalculado.precoTotalIpi),
              precoTotalComImposto: formatCurrency(
                itemCalculado.precoTotalComImposto,
              ),
            }
          })
        : [],
    [pedido.itens],
  )

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'perder_item', data: ItemPedidoModel) => {
      switch (event) {
        case 'edit':
          openForm(data)
          break
        case 'delete':
          openConfirm(data)
          break
        case 'perder_item':
          openDialog(data)
          break
      }
    },
    [openConfirm, openForm],
  )

  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      await updateBloqueioItemPedido()
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [itensPedidoSelecionados])

  async function updateBloqueioItemPedido() {
    const possuiItensPedido = itensPedidoSelecionados.length > 0

    if (possuiItensPedido) {
      await mutateAsync({
        itensPedidoSelecionados,
      })

      setItensPedidoSelecionados([])
    }
  }

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigoProduto',
        label: 'Código',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'nomeProduto',
        label: 'Nome',
        options: {
          setCellProps: () => ({
            style: {
              minWidth: '240px',
            },
          }),
        },
      },
      {
        name: 'quantidade',
        label: 'Quantidade',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'precoDeTabela',
        label: 'Preço de Tabela',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'precoUnitarioLiquido',
        label: 'Preço de Venda',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'precoTotalLiquido',
        label: `Total (R$${formatCurrency(pedido.totalLiquido)})`,
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'precoTotalIpi',
        label: `Total IPI (R$${formatCurrency(pedido.totalIpi)})`,
        options: {
          display: Boolean(configuracoes?.usa_calculo_imposto),
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'precoTotalComImposto',
        label: `Total Nota (R$${formatCurrency(pedido.totalComImposto)})`,
        options: {
          display: Boolean(configuracoes?.usa_calculo_imposto),
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'itemBloqueado',
        label: 'Bloqueado',
        options: {
          display: usuarioLogado.isadmin,
          setCellProps: () => ({
            style: {
              width: '70px',
              height: '42px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value, tableMeta) => {
            const rowIndex = tableMeta.rowIndex
            const itemPedidoId = pedido.itens[rowIndex].uuid || ''

            return (
              <>
                {itensPedidoSelecionados.some(
                  (item) => item.itemPedidoId === itemPedidoId,
                ) ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size={14} />
                  </Box>
                ) : (
                  <Checkbox
                    disabled={!podeEditar}
                    name="itemBloqueado"
                    checked={Boolean(value)}
                    onChange={(e) => {
                      const isChecked = e.target.checked

                      setItensPedidoSelecionados((prevState) => [
                        ...prevState,
                        { itemPedidoId, itemBloqueado: isChecked },
                      ])
                    }}
                  />
                )}
              </>
            )
          },
        },
      },
      // {
      //   name: 'precoTotalIcms',
      //   label: 'ICMS',
      //   options: {
      //     setCellProps: CurrencyCellStyle,
      //   },
      // },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          display: podeEditar,
          filter: true,
          sort: false,
          empty: true,
          setCellProps: () => ({
            width: '124px',
            textAlign: 'right',
          }),
          customBodyRender: (value) => {
            const _data = findData(pedido.itens || [], value)

            const itemBloqueadoParaNaoAdministradores =
              !usuarioLogado.isadmin && _data?.itemBloqueado === true
            return (
              <DataTableOptions
                disabled={itemBloqueadoParaNaoAdministradores}
                displayUpdateButton={true}
                value={_data}
                onClick={handleClickItem}
                // extraOptions={[
                //   {
                //     id: 'perder_item',
                //     icon: 'archive',
                //     visible: true,
                //     iconButtonProps: {
                //       color: 'primary',
                //     },
                //   },
                // ]}
              />
            )
          },
        },
      },
    ],
    [pedido, handleClickItem, itensPedidoSelecionados, configuracoes],
  )

  return (
    <>
      <DataTable
        columns={columns}
        data={_itens}
        // isLoading={isLoading}
        // isFetching={isFetching}
        options={{ pagination: false, responsive: 'standard' }}
      />
      <PerderItemForm
        isOpen={isOpen}
        closeDialog={closeDialog}
        item={itemPerdidoSelecionado}
      />
    </>
  )
}
