import { useState } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import {
  Box,
  Collapse,
  Icon,
  ListItemText,
  styled,
  useTheme,
} from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'

import { MenuItemProps } from 'components/Menu'
import MenuItem from 'components/Menu/componetns/MenuItem'
import novoIcon from 'assets/images/novo.svg'
import FullMenu from '../..'

interface FullMenuItemProps {
  menuItem: MenuItemProps
}

const SmallIcon = styled(Icon)({})

export default function FullMenuItem(props: FullMenuItemProps) {
  const { menuItem } = props
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  if (!menuItem.visible) return null
  const isExpandable: boolean = Boolean(
    menuItem?.items && menuItem?.items.length > 0,
  )

  function toggleExpand() {
    setExpanded(!expanded)
  }

  const splittedPath = location.pathname.split('/')
  const pathName = splittedPath[1]

  const splittedLink = menuItem.link?.split('/') || []
  const linkName = splittedLink[1]

  return (
    <Box>
      <MenuItem
        onClick={() => {
          if (menuItem?.link) {
            navigate(menuItem.link)
            return
          }
          toggleExpand()
        }}
        selected={pathName === linkName}
      >
        {!!menuItem.icon && !menuItem.isNew && (
          <SmallIcon fontSize="small">{menuItem.icon}</SmallIcon>
        )}
        <ListItemText
          sx={{
            '& .MuiTypography-root': {
              fontSize: '14px',
              fontWeight: 'bold',
              color: theme.palette.grey[600],
            },
          }}
          primary={menuItem.label}
        />
        {menuItem.isNew && <img src={novoIcon} width="26px" />}
        {isExpandable && !expanded && <ExpandMore />}
        {isExpandable && expanded && <ExpandLess />}
      </MenuItem>

      {menuItem?.items && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <FullMenu menus={menuItem?.items || []} />
        </Collapse>
      )}
    </Box>
  )
}
