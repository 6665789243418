import { useState } from 'react'

import { FilterProvider, FormProvider } from '@data-c/providers'
import { ButtonContainer, Content, PageTitle } from '@data-c/ui'

import { Container } from '@mui/material'

import useProdutos, {
  filters,
  ProdutoFilters1,
} from 'hooks/queries/useProdutos'

import Delete from 'components/Delete'

import Table from './components/Table'
import Filter from './components/Filter'
import Form from './components/Form'
import { TipoVariacaoEnum } from 'components/Inputs/DropDownTipoVariacao'
import HelpButton from 'components/HelpButton'

export default function Produtos() {
  const [appliedFilters, setFilters] = useState<ProdutoFilters1>(
    {} as ProdutoFilters1,
  )

  const { useDelete } = useProdutos()
  const { mutateAsync } = useDelete()

  return (
    <Container>
      <FormProvider
        initialFormValues={{
          ativo: true,
          unidade: 'un',
          tipoVariacao: TipoVariacaoEnum.NENHUMA,
        }}
      >
        <PageTitle title="Produtos">
          <ButtonContainer>
            <FilterProvider filterValues={filters}>
              <Filter
                onApplyFilters={(_appliedFilters) => {
                  setFilters(_appliedFilters)
                }}
              />
            </FilterProvider>
            <Form />
            <HelpButton link="https://datacsistemashelp.zendesk.com/hc/pt-br/articles/28764082341396-Produtos"></HelpButton>
          </ButtonContainer>
        </PageTitle>
        <Content>
          <Table filters={appliedFilters} />
          <Delete onDelete={mutateAsync} />
        </Content>
      </FormProvider>
    </Container>
  )
}
