import { Stack, Typography } from '@mui/material'
import { AppNotificationsModel } from 'hooks/queries/useAppNotifications'

interface LoadingNotificationsProps {
  isFetching: boolean
  notifications: AppNotificationsModel[]
}

export default function LoadingNotifications(props: LoadingNotificationsProps) {
  const { isFetching, notifications } = props

  return (
    <>
      {isFetching && notifications.length === 0 && (
        <Stack height="150px" alignItems="center" justifyContent="center">
          <Typography variant="body2">Carregando...</Typography>
        </Stack>
      )}
    </>
  )
}
