import { SortProps, isExists } from '@data-c/hooks'
import { FilterOptions } from '@data-c/providers'
import BaseModel from 'interfaces/BaseModel'
import HttpRequestInterface from 'interfaces/HttpRequestInterface'
import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import api from 'services/api'
import {
  useMutation,
  useQuery as useTQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import HooksResultInterface from 'interfaces/CrudResultInterface'
import { buildActionText } from 'hooks/useCrud'
import useNotification from 'hooks/useNotifications'
import { TabelaPrecoModel } from './useTabelaPreco'
import { CondicaoPagamentoModel } from './useCondicaoPagamento'
import { FormaPagamentoModel } from './useFormaPagamento'
import ErrorInterface from 'interfaces/ErrorInterface'
import { TimeVendasModel } from './useTimeVendas'
import { ClientesModel } from './useClientes'
import { MotivosDePerdaModel } from './useMotivosDePerda'
import { ProdutoModel } from './useProdutos'
import { AtividadeComentarios } from './useAtividadeComentarios'
import { DateTime } from 'luxon'
import { DateRange } from 'components/Inputs/DateRangePicker'
import { SelectableItem } from 'pages/Pedido/components/TabNegociacao/components/MarcarComoPerdidaForm/components/SelecionarItens'
import { EtapaModel } from './useFunilEtapa'
import { CrmAtividadesModel } from './useCrmAtividades'

export interface SituacaoInterface {
  label: string
  value: string
}

export interface PedidoFilters {
  codigo: FilterOptions<string>
  dataInicial: FilterOptions<string>
  dataFinal: FilterOptions<string>
  situacao: FilterOptions<Array<string>>
  situacaoKeyValue: FilterOptions<Array<SituacaoInterface>>
  cliente: FilterOptions<ClientesModel>
  clienteUuid: FilterOptions<string>
  representanteUuid: FilterOptions<string>
  representante: FilterOptions<TimeVendasModel>
  dateRange?: FilterOptions<DateRange>
  carga?: FilterOptions<string>
  operacao?: FilterOptions<string>
}

export interface PedidoFilters1 {
  plainQuery?: string | null
  codigo?: string
  dataInicial?: string | null
  dataFinal?: string | null
  dateRange?: DateRange
  situacao?: Array<string>
  situacaoKeyValue?: Array<SituacaoInterface> | []
  cliente?: ClientesModel
  clienteUuid?: string
  representante?: TimeVendasModel
  representanteUuid?: string
  carga?: string
  operacao?: string
}

export const filters: PedidoFilters = {
  codigo: {
    label: 'Código',
  },
  dataInicial: {
    label: 'Data Inicial',
  },
  dataFinal: {
    label: 'Data Final',
  },
  situacao: {
    value: [],
    label: '',
  },
  situacaoKeyValue: {
    value: [],
    label: 'Situação',
  },
  cliente: {
    label: 'Cliente',
  },
  clienteUuid: {
    label: 'Cliente',
  },
  representante: {
    label: 'Vendedor',
  },
  representanteUuid: {
    value: '',
    label: 'Vendedor',
  },
  carga: {
    value: '',
    label: 'Núm. Carga',
  },
  dateRange: {
    label: 'Período',
    value: {
      tipo: 'month',
      firstDate: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
      secondDate: DateTime.now().endOf('day').toFormat('yyyy-MM-dd'),
    },
  },
  operacao: {
    label: 'Operação',
  },
}

export const ajustesMap: Record<number, number> = {
  0: 0,
  1: 100,
  2: 50,
  3: 25,
}

export enum SituacaoPedidoEnum {
  WEB = 'N',
  MOBILE = 'M',
  FECHADO = 'E',
  ANALISE = 'A',
  CANCELADO = 'C',
  LIBERADO = 'L',
  ORCAMENTO = 'O',
  COTACAO_SOLICITACAO = 'T',
  COTACAO_ANALISE = 'R',
  COTACAO_APROVADA = 'U',
  NEGOCIACAO_PERDIDA = 'S',
}

export enum OperacaoEnum {
  VENDA = 'V',
  BONIFICACAO = 'B',
}

interface ValoresIndicadorModel {
  duplicatasEmAberto: number
  pedidosEmAberto: number
}

export interface PedidoModel extends BaseModel {
  uuid?: string
  codigo?: number
  pessoa?: string
  vendedor?: string
  representante?: TimeVendasModel
  cliente?: ClientesModel
  tabelaPreco?: TabelaPrecoModel | null
  tabelaPrecoUuid?: string
  condicaoPagamento?: CondicaoPagamentoModel | null
  condicaoPagamentoUuid?: string
  formaPagamento?: FormaPagamentoModel | null
  formaPagamentoUuid?: string
  itens: Array<ItemPedidoModel>
  itensPerdidos: Array<ItemPerdidoModel>
  comentarios: Array<AtividadeComentarios>
  situacaoAsText?: string
  situacao?: SituacaoPedidoEnum
  vendaAsBrazilianDate?: string
  venda: string
  operacaoAsText?: string
  totalIpi: number
  totalLiquido: number
  totalLiquidoAsBrazilianCurrency: number
  totalComImposto: number
  observacao?: string
  validadeCotacao: string | null
  iscotacao?: boolean
  motivoPerda?: MotivosDePerdaModel | null
  motivoPerdaUuid?: string
  observacaoMotivoPerda?: string
  ajuste?: number | string
  snapshot?: object
  pesoTotal?: number
  pedidosRelacionados?: Array<PedidoModel>
  funilVendaEtapaUuid?: String
  funilVendaEtapa?: EtapaModel
  atividades?: Array<CrmAtividadesModel>
  pedidoRelacionado?: PedidoModel
  valoresIndicador?: ValoresIndicadorModel
}

export interface ItemPedidoModel extends BaseModel {
  produto?: {
    uuid: string
    codigo: string
    nome: string
    fracionado: boolean
  } | null
  produtoUuid: string | null
  pedidoUuid: string | null
  quantidade: number
  precoDeTabela: number
  precoTotalLiquido: number
  precoTotalBruto: number
  precoTotalIpi: number
  precoUnitarioIpi: number
  precoTotalIcmsst: number
  precoTotalIcms: number
  precoUnitarioLiquido: number
  precoTotalComImposto: number
  percentualDesconto: number
  percentualIpi: number
  observacao?: string
  corUuid: string
  itemBloqueado?: boolean
}

interface IniciarPedidoModel {
  vendedor: string
  pessoa: string
  situacao?: SituacaoPedidoEnum
  iscotacao?: boolean
  funilVendaEtapaUuid?: string
  ajuste?: number
}

export interface ItensPedidoSelecionados {
  itemPedidoId: string
  itemBloqueado: boolean
}

export interface ItemPerdidoRequestModel {
  itemPedidoUuid: string
  motivoPerdaUuid?: string
  observacaoMotivoPerda?: string
}

export interface ItemPerdidoModel {
  uuid: string
  produto: ProdutoModel
  quantidade: number
  preco: number
  percentualDesconto: number
  pedidoUuid?: string
  clienteUuid?: string
  cliente?: ClientesModel
  motivoPerdaUuid?: string
  motivoPerda?: MotivosDePerdaModel
  observacaoMotivoPerda?: string
}

export interface NegociacaoPerdidaRequest {
  negociacaoUuid: string
  motivoPerdaUuid: string
  observacaoMotivoPerda: string | null
  itensSelecionados: Array<SelectableItem>
}

export interface NegociacaoPerdidaResponse {
  pedido1: PedidoModel
  pedido2: PedidoModel | null
}

export interface PerderItemModel {
  item?: ItemPedidoModel
  motivoPerda?: MotivosDePerdaModel | null
  motivoPerdaUuid?: string | null
  observacaoMotivoPerda?: string
  informarObservacao?: boolean
}

export async function obterPedidos(
  params: HttpRequestInterface<PedidoFilters1>,
  sort?: SortProps,
): Promise<HttpResponseInterface<PedidoModel>> {
  const { pagination: _pagination, queryParams } = params
  const situacoes = queryParams?.situacaoKeyValue
    ? queryParams?.situacaoKeyValue.map((s) => s.value)
    : null
  const nQueryParams = {
    ...queryParams,
    situacao: situacoes,
  }
  const response = await api.get('vendas/pedidos', {
    params: nQueryParams,
    headers: {
      'DC-Page': _pagination.page,
      'DC-PageSize': _pagination.pageSize,
      'DC-SortName': sort?.column,
      'DC-SortDirection': sort?.direction,
    },
  })

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export async function obterCotacoes(
  params: HttpRequestInterface<PedidoFilters1>,
  sort?: SortProps,
): Promise<HttpResponseInterface<PedidoModel>> {
  const { pagination: _pagination, queryParams } = params
  const situacoes = queryParams?.situacaoKeyValue
    ? queryParams?.situacaoKeyValue.map((s) => s.value)
    : null
  const nQueryParams = {
    ...queryParams,
    situacao: situacoes,
  }
  const response = await api.get('vendas/cotacoes', {
    params: nQueryParams,
    headers: {
      'DC-Page': _pagination.page,
      'DC-PageSize': _pagination.pageSize,
      'DC-SortName': sort?.column,
      'DC-SortDirection': sort?.direction,
    },
  })

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export async function obterPedidoPorId(id: string): Promise<PedidoModel> {
  const response = await api.get(`vendas/pedidos/${id}`)
  return response.data
}

export function useQueryObterPedidoPorId(id: string) {
  return useTQuery<PedidoModel>(['PEDIDO', id], () => {
    return obterPedidoPorId(id)
  })
}

export function useQueryObterPedidos(
  params: HttpRequestInterface<PedidoFilters1>,
  sort?: SortProps,
) {
  return useTQuery<
    HttpResponseInterface<PedidoModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['PEDIDOS', params, sort], () => {
    return obterPedidos(params, sort)
  })
}

export function useQueryObterCotacoes(
  params: HttpRequestInterface<PedidoFilters1>,
  sort?: SortProps,
) {
  return useTQuery<HttpResponseInterface<PedidoModel>>(
    ['COTACOES', params, sort],
    () => {
      return obterCotacoes(params, sort)
    },
  )
}

export function useSubmitIniciarPedido() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function iniciarPedido(data: IniciarPedidoModel): Promise<PedidoModel> {
    const response = await api.post<PedidoModel>('vendas/pedidos', data)
    return response.data
  }

  return useMutation<PedidoModel, AxiosError, IniciarPedidoModel>(
    (data) => iniciarPedido(data),
    {
      onSuccess(data) {
        if (data) {
          notifications.notifySuccess('O Pedido foi iniciado com sucesso')
          queryClient.invalidateQueries(['PEDIDO'])
        }
      },
    },
  )
}

export function useMarcarNegociacaoComoPerdida() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  async function marcarNegociacaoComoPerdida(
    data: NegociacaoPerdidaRequest,
  ): Promise<NegociacaoPerdidaResponse> {
    const response = await api.put(
      `/vendas/pedidos/pedido/${data.negociacaoUuid}/marcar-negociacao-como-perdida`,
      data,
    )

    return response.data
  }

  return useMutation<
    NegociacaoPerdidaResponse,
    AxiosError,
    NegociacaoPerdidaRequest
  >((data) => marcarNegociacaoComoPerdida(data), {
    onSuccess(_: NegociacaoPerdidaResponse) {
      queryClient.invalidateQueries(['PEDIDO'])
    },
    onError(err) {
      notifications.notifyException(err)
    },
  })
}

export function useUpdatePedido(
  configs: { attempts: number } = { attempts: 3 },
) {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  async function updatePedido(pedido: PedidoModel): Promise<PedidoModel> {
    const data = {
      observacao: pedido.observacao,
      validadeCotacao: pedido.validadeCotacao,
      tabelaPrecoUuid: pedido.tabelaPrecoUuid || null,
      condicaoPagamentoUuid: pedido?.condicaoPagamentoUuid || null,
      formaPagamentoUuid: pedido?.formaPagamentoUuid || null,
      funilVendaEtapaUuid: pedido?.funilVendaEtapaUuid || null,
      ajuste: pedido?.ajuste,
    }

    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/pedido/${pedido.uuid}`,
      data,
      configs,
    )

    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => updatePedido(data),
    {
      onSuccess(pedido: PedidoModel) {
        if (pedido.situacao === SituacaoPedidoEnum.NEGOCIACAO_PERDIDA) {
          notifications.notifySuccess(`A negociação foi marcada como perdida`)
        }
        queryClient.invalidateQueries(['PEDIDO', pedido.uuid])
      },
      onError(err, pedido: PedidoModel) {
        if (pedido.situacao === SituacaoPedidoEnum.NEGOCIACAO_PERDIDA) {
          notifications.notifyException(err)
        }
      },
    },
  )
}

export function useSubmitItemPedido() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  async function saveItemPedido(
    pedidoUuid: string,
    item: ItemPedidoModel,
  ): Promise<HooksResultInterface<ItemPedidoModel>> {
    if (isExists(item, 'uuid')) {
      const response = await api.put<ItemPedidoModel>(
        `vendas/pedidos/item-pedido/${item.uuid}`,
        item,
      )
      return { data: response.data, operation: 'update' }
    }

    const response = await api.post(
      `vendas/pedidos/${pedidoUuid}/item-pedido/`,
      item,
    )
    return { data: response.data, operation: 'create' }
  }

  return useMutation<
    HooksResultInterface<ItemPedidoModel>,
    AxiosError,
    {
      pedidoUuid: string
      item: ItemPedidoModel
    }
  >((data) => saveItemPedido(data.pedidoUuid, data.item), {
    onSuccess(result: HooksResultInterface<ItemPedidoModel>) {
      const acao = buildActionText(result.operation, 'male')
      // toast.dismiss()
      notifications.notifySuccess(`Item ${acao} com sucesso`)
      queryClient.invalidateQueries(['PEDIDO'])
    },
    onError() {
      notifications.notifyError(
        'Ocorreu um erro ao tentar processar a requisição',
      )
    },
  })
}

export async function updateBloqueioItemPedido(
  itensPedidoSelecionados: Array<ItensPedidoSelecionados>,
): Promise<ItemPedidoModel> {
  const response = await api.put(
    `vendas/pedidos/item-pedido/bloqueio-item-pedido`,
    itensPedidoSelecionados,
  )
  return response.data
}

export function useUpdateBloqueioItemPedido() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    ItemPedidoModel,
    AxiosError,
    {
      itensPedidoSelecionados: Array<ItensPedidoSelecionados>
    }
  >((data) => updateBloqueioItemPedido(data.itensPedidoSelecionados), {
    onSuccess(_: ItemPedidoModel) {
      notifications.notifySuccess('Item alterado com sucesso')
      queryClient.invalidateQueries(['PEDIDO'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export function useUpdateCotacao() {
  const queryClient = useQueryClient()

  async function updateCotacao(pedido: PedidoModel): Promise<PedidoModel> {
    const data = {
      observacao: pedido.observacao,
      validadeCotacao: pedido.validadeCotacao,
      tabelaPrecoUuid: pedido.tabelaPrecoUuid || null,
      condicaoPagamentoUuid: pedido?.condicaoPagamentoUuid || null,
      formaPagamentoUuid: pedido?.formaPagamentoUuid || null,
    }

    const response = await api.put<PedidoModel>(
      `/vendas/cotacoes/cotacao/${pedido.uuid}`,
      data,
      { attempts: 3 },
    )

    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => updateCotacao(data),
    {
      onSuccess(_: PedidoModel) {
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError() {},
    },
  )
}

export function useSubmitConcluirCotacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function concluirCotacao(pedido: PedidoModel): Promise<PedidoModel> {
    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/${pedido.uuid}/concluir-cotacao`,
    )
    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => concluirCotacao(data),
    {
      onSuccess(_: PedidoModel) {
        notifications.notifySuccess(`A cotação foi aprovada com sucesso`)
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useSubmitAnalisarCotacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function analisarCotacao(pedido: PedidoModel): Promise<PedidoModel> {
    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/${pedido.uuid}/analisar-cotacao`,
    )
    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => analisarCotacao(data),
    {
      onSuccess(_: PedidoModel) {
        notifications.notifySuccess(
          `A situação da cotação foi atualizada para "Análise"`,
        )
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useSubmitFecharNegociacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function fecharNegociacao(pedido: PedidoModel): Promise<PedidoModel> {
    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/${pedido.uuid}/fechar-negociacao`,
    )
    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => fecharNegociacao(data),
    {
      onSuccess(_: PedidoModel) {
        notifications.notifySuccess(`A negociação foi fechada com sucesso"`)
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError(error) {
        const errorData = notifications.extractAxiosErrorData(error)
        if (errorData?.code !== 'E_LIMITE_ESTOURADO') {
          notifications.notifyException(error)
        }
      },
    },
  )
}

export function useSubmitReabrirCotacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function reabrirCotacao(pedido: PedidoModel): Promise<PedidoModel> {
    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/${pedido.uuid}/reabrir-cotacao`,
    )
    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => reabrirCotacao(data),
    {
      onSuccess(_: PedidoModel) {
        notifications.notifySuccess(`A cotação foi reaberta com sucesso`)
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useSubmitSolicitarCotacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function solicitarCotacao(pedido: PedidoModel): Promise<PedidoModel> {
    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/${pedido.uuid}/solicitar-cotacao`,
    )
    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => solicitarCotacao(data),
    {
      onSuccess(_: PedidoModel) {
        notifications.notifySuccess(`A cotação foi solicitada com sucesso`)
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useSubmitCancelarSolicitacaoCotacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function cancelarSolicitacaoCotacao(
    pedido: PedidoModel,
  ): Promise<PedidoModel> {
    const response = await api.put<PedidoModel>(
      `/vendas/pedidos/${pedido.uuid}/cancelar-solicitacao-cotacao`,
    )
    return response.data
  }

  return useMutation<PedidoModel, AxiosError, PedidoModel>(
    (data) => cancelarSolicitacaoCotacao(data),
    {
      onSuccess(_: PedidoModel) {
        notifications.notifySuccess(
          `A solicitação de cotação foi cancelada com sucesso`,
        )
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useDeleteItemPedido() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function deletarItemPedido(
    data: ItemPedidoModel,
  ): Promise<ItemPedidoModel> {
    await api.delete(`/vendas/pedidos/item-pedido/${data?.uuid}`)
    return data
  }

  return useMutation<any, AxiosError, ItemPedidoModel>(deletarItemPedido, {
    onSuccess: async () => {
      queryClient.invalidateQueries(['PEDIDO'])
      notifications.notifySuccess('O item do pedido foi excluído com sucesso')
    },
    onError: (err) => {
      notifications.notifyException(err)
    },
  })
}

export function useDeletePedido() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function deletarPedido(data: PedidoModel): Promise<PedidoModel> {
    await api.delete(`/vendas/pedidos/${data?.uuid}`)
    return data
  }

  return useMutation<any, AxiosError, PedidoModel>(deletarPedido, {
    onSuccess: async () => {
      queryClient.invalidateQueries(['PEDIDOS'])
      notifications.notifySuccess('O pedido foi excluído com sucesso')
    },
    onError: (err) => {
      notifications.notifyException(err)
    },
  })
}

export function usePerderItem() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function perderItem(
    itemPerdido: ItemPerdidoRequestModel,
  ): Promise<PerderItemModel> {
    const response = await api.post<ItemPerdidoRequestModel>(
      `/crm/item-perdido`,
      itemPerdido,
    )
    return response.data
  }

  return useMutation<PerderItemModel, AxiosError, ItemPerdidoRequestModel>(
    (data) => perderItem(data),
    {
      onSuccess(_: PerderItemModel) {
        notifications.notifySuccess(
          `O item foi marcado como perdido com sucesso`,
        )
        queryClient.invalidateQueries(['PEDIDO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}
