import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import Home from './pages/Home'
import Login from './pages/Login'
import Layout from 'pages/Layout'
import Configuracoes from 'pages/Configuracoes'
import Pedidos from 'pages/Pedidos'
import Pedido from 'pages/Pedido'
import Clientes from 'pages/Clientes'
import MixProdutos from 'pages/MixProdutos'
import ClasseProdutos from 'pages/ClasseProdutos'
import Produtos from 'pages/Produtos'
import LinhaProdutos from 'pages/LinhaProdutos'
import GrupoProdutos from 'pages/GrupoProdutos'
import FamiliaProdutos from 'pages/FamiliaProdutos'
import FormaPagamentos from 'pages/FormaPagamentos'
import FormaPagamento from 'pages/FormaPagamento'
import CondicaoPagamento from 'pages/CondicaoPagamento'
import MixProduto from 'pages/MixProduto'
import TabelasPrecos from 'pages/TabelasPrecos'
import FatorPrecificacao from 'pages/FatorPrecificacao'
import Vendedores from 'pages/Vendedores'
import Vendedor from 'pages/Vendedor'
import CrmAtividades from 'pages/CrmAtividades'
import Produto from 'pages/Produto'
import ContasDeUsuarios from 'pages/ContasDeUsuarios'
import TabelaPreco from 'pages/TabelaPreco'
import MotivosDePerda from 'pages/MotivosDePerda'
import Cliente from 'pages/Cliente'
import MetasVendas from 'pages/Relatorios/MetasVendas'
import Role from 'pages/Role'
import Roles from 'pages/Roles'
import AtivacaoClientes from 'pages/Relatorios/AtivacaoClientes'
import ABCProdutos from 'pages/Relatorios/ABCProdutos'
import FichaCliente from 'pages/Relatorios/FichaCliente'
import MobileCrmAtividades from 'pages/Mobile/CrmAtividades'
import MobileComentarios from 'pages/Mobile/Comentarios'
import MLayout from 'pages/Mobile/MLayout'
import NegociacoesPerdidas from 'pages/Relatorios/NegociacoesPerdidas'
import ABCClientes from 'pages/Relatorios/ABCClientes'
import TaxaConversaoNegocio from 'pages/Relatorios/TaxaConversaoNegocio'
import Fallback from 'components/Fallback'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import useLogs from 'hooks/useLogs'
import MigrarConta from 'pages/Mobile/MigrarConta'
import FunilVenda from 'pages/CrmFunilVenda'
import { PreserveNavigationDataProvider } from 'contexts/PreserveNavigationDataContext'
import ComparacaoPerformanceProdutos from 'pages/Relatorios/ComparacaoPerformanceProdutos'
import ImportarEntidade from 'pages/ImportarEntidade'
import FunisDeVendas from 'pages/FunisDeVenda'
import FunilDeVenda from 'pages/FunilDeVenda'

export default function AppRoutes() {
  const location = useLocation()
  const { sendLog } = useLogs()
  useEffect(() => {
    sendLog({
      grupo: 'navegacao',
      acao: location.pathname,
    })
  }, [location])

  return (
    <Sentry.ErrorBoundary
      fallback={(errorProps) => <Fallback {...errorProps} />}
    >
      <Routes>
        <Route index path="/login" element={<Login />} />
        <Route index path="/" element={<Login />} />
        <Route
          path="/home"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/configuracoes"
          element={
            <Layout>
              <Configuracoes />
            </Layout>
          }
        />
        <Route
          path="/pedidos"
          element={
            <Layout role="negociacoes.visibilidade">
              <PreserveNavigationDataProvider>
                <Outlet />
              </PreserveNavigationDataProvider>
            </Layout>
          }
        >
          <Route path="lista" element={<Pedidos />} />
          <Route path="pedido/:id" element={<Pedido />} />
        </Route>

        <Route
          path="/clientes"
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="lista" element={<Clientes />} />
          <Route path="cliente/:id" element={<Cliente />} />
        </Route>

        <Route
          path="/vendedores"
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="lista" element={<Vendedores />} />
          <Route path="vendedor/:id" element={<Vendedor />} />
        </Route>

        <Route
          path="/mix-produtos"
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="lista" element={<MixProdutos />} />
          <Route path="mix-produto/:id" element={<MixProduto />} />
        </Route>
        <Route
          path="/produtos"
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="lista" element={<Produtos />} />
          <Route path="produto/:id" element={<Produto />} />
        </Route>
        <Route
          path="/classe-produtos"
          element={
            <Layout>
              <ClasseProdutos />
            </Layout>
          }
        />
        <Route
          path="/grupo-produtos"
          element={
            <Layout>
              <GrupoProdutos />
            </Layout>
          }
        />
        <Route
          path="/linha-produtos"
          element={
            <Layout>
              <LinhaProdutos />
            </Layout>
          }
        />
        <Route
          path="/familia-produtos"
          element={
            <Layout>
              <FamiliaProdutos />
            </Layout>
          }
        />
        <Route
          path="/forma-pagamento"
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="lista" element={<FormaPagamentos />} />
          <Route path="forma-pagamento/:id" element={<FormaPagamento />} />
        </Route>
        <Route
          path="/condicao-pagamento"
          element={
            <Layout>
              <CondicaoPagamento />
            </Layout>
          }
        />
        <Route
          path="/tabelas-precos"
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="lista" element={<TabelasPrecos />} />
          <Route path="tabela-preco/:id" element={<TabelaPreco />} />
        </Route>
        <Route
          path="/fator-precificacao"
          element={
            <Layout>
              <FatorPrecificacao />
            </Layout>
          }
        />
        <Route
          path="/importar"
          element={
            <Layout>
              <ImportarEntidade />
            </Layout>
          }
        />
        <Route
          path="/crm/atividades"
          element={
            <Layout>
              <CrmAtividades />
            </Layout>
          }
        />
        <Route
          path="funis-venda"
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="lista" element={<FunisDeVendas />} />
          <Route path="funil-venda/:id" element={<FunilDeVenda />} />
        </Route>
        <Route
          path="/motivos-de-perda"
          element={
            <Layout>
              <MotivosDePerda />
            </Layout>
          }
        />
        <Route
          path="/contas"
          element={
            <Layout>
              <ContasDeUsuarios />
            </Layout>
          }
        />
        <Route
          path="/permissoes"
          element={
            <Layout>
              <Roles />
            </Layout>
          }
        />
        <Route
          path="/permissao/:id"
          element={
            <Layout>
              <Role />
            </Layout>
          }
        />
        <Route
          path="/meta-vendas"
          element={
            <Layout>
              <MetasVendas />
            </Layout>
          }
        />
        <Route
          path="/abc-clientes"
          element={
            <Layout>
              <ABCClientes />
            </Layout>
          }
        />
        <Route
          path="/relatorio-performance-produtos"
          element={
            <Layout>
              <ComparacaoPerformanceProdutos />
            </Layout>
          }
        />
        <Route
          path="/taxa-conversao-negocio"
          element={
            <Layout>
              <TaxaConversaoNegocio />
            </Layout>
          }
        />
        <Route
          path="/abc-produtos"
          element={
            <Layout>
              <ABCProdutos />
            </Layout>
          }
        />
        <Route
          path="/ficha-clientes"
          element={
            <Layout>
              <FichaCliente />
            </Layout>
          }
        />
        <Route
          path="/ativacao-clientes"
          element={
            <Layout>
              <AtivacaoClientes />
            </Layout>
          }
        />
        <Route
          path="/negociacoes-perdidas"
          element={
            <Layout>
              <NegociacoesPerdidas />
            </Layout>
          }
        />

        <Route
          path="funil-venda"
          element={
            <Layout>
              <FunilVenda />
            </Layout>
          }
        />
        <Route
          path="m/migrar-conta/:chave"
          element={
            <MLayout isProtected={false}>
              <MigrarConta />
            </MLayout>
          }
        />
        <Route
          path="m/atividades"
          element={
            <MLayout>
              <MobileCrmAtividades />
            </MLayout>
          }
        />
        <Route
          path="m/comentarios"
          element={
            <MLayout>
              <MobileComentarios />
            </MLayout>
          }
        />

        <Route path="*" element={<div>Not Found</div>} />
      </Routes>
    </Sentry.ErrorBoundary>
  )
}
