import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export default function DropDownOperacaoPadrao(props: TextFieldProps) {
  return (
    <TextField select {...props}>
      <MenuItem value="V">Venda</MenuItem>
      <MenuItem value="D">Devolução</MenuItem>
      <MenuItem value="P">Pronta Entrega</MenuItem>
      <MenuItem value="B">Bonificação</MenuItem>
      <MenuItem value="T">Troca</MenuItem>
    </TextField>
  )
}
