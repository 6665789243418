import { HttpResponseInterface, PaginationProps } from '@data-c/hooks'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useCrud, { read, RequestInterface } from 'hooks/useCrud'
import api from 'services/api'
import BaseModel from 'interfaces/BaseModel'
import { AxiosError } from 'axios'
import { ProdutoModel } from './useProdutos'
import { FatorPrecificacaoModel } from './useFatorPrecificacao'
import ErrorInterface from 'interfaces/ErrorInterface'
import HttpRequestInterface from 'interfaces/HttpRequestInterface'
import useNotification from 'hooks/useNotifications'
import { FilterOptions } from '@data-c/providers'

interface CorModel {
  uuid: string
  codigo: string
  nome: string
}

interface GradeModel {
  uuid: string
  codigo: string
  nome: string
}

export interface TabelaPrecoFilter {
  nome: FilterOptions<string>
  codigo: FilterOptions<string>
  ativo: FilterOptions<boolean>
}

export interface TabelaPrecoFilter1 {
  nome: string
  codigo: string
  ativo: boolean
  plainQuery?: string
}

export const filters: TabelaPrecoFilter = {
  nome: {
    label: 'Nome',
  },
  codigo: {
    label: 'Código',
  },
  ativo: {
    label: 'Ativo',
    value: true,
  },
}

export interface ItemTabelaPrecoFilter {
  nomeProduto: FilterOptions<string>
  codigoProduto: FilterOptions<string>
  codigoNomeCor: FilterOptions<string>
  codigoNomeGrade: FilterOptions<string>
  apenasQuantidadeMinima: FilterOptions<boolean>
}

export interface ItemTabelaPrecoFilter1 {
  nomeProduto: string
  codigoProduto: string
  codigoNomeCor: string
  codigonomeGrade: string
  apenasQuantidadeMinima: boolean
  plainQuery?: string
}

export const filtersItens: ItemTabelaPrecoFilter = {
  nomeProduto: {
    label: 'Nome',
  },
  codigoProduto: {
    label: 'Código',
  },
  codigoNomeCor: {
    label: 'codigoNomeCor',
  },
  codigoNomeGrade: {
    label: 'codigoNomeGrade',
  },
  apenasQuantidadeMinima: {
    label: 'qtdMinima',
    value: false,
  },
}

export interface DescontoAgregadoModel {
  uuid?: string
  pdesconto: number
  pesoValor: number
  tipoPesoValor: string
  pesoValorMinimo: number
  uuidTabelaPreco: string
}

export interface ItemTabelaPrecoModel extends BaseModel {
  tabelaPrecoUuid: string
  tabelPreco: TabelaPrecoModel
  produto: ProdutoModel
  cor?: CorModel
  grade?: GradeModel
  preco?: number
  quantidiadeMinima?: number
  valorUnitarioIpi: number
  percentualComissao?: number
}

export interface TabelaPrecoModel extends BaseModel {
  codigo?: number
  nome: string
  fatorPromocional?: FatorPrecificacaoModel
  fatorPromocionalUuid?: string
  operacaoPadrao?: string
  ativo?: boolean
  descontoAgregado: DescontoAgregadoModel
}

interface QueryParamsInterface {
  plainQuery?: string | null
  clienteUuid?: string
  vendedorUuid?: string
  tabelaPrecoUuid?: string
  pagination?: PaginationProps
  ativo?: boolean
}

interface VinculoProdutosInputProps {
  tabelaPrecoUuid: string
  produtosSelecionadosIds: Array<string>
}

interface VinculoProdutosComPrecoInputProps {
  tabelaPrecoUuid: string
  produtoUuid: string[]
  preco: string
}

export interface UpdateVinculoProdutosInputProps {
  uuid?: string
  produtoUuid: string
  preco?: number
  quantidadeMinima?: number
}

export async function obterItemTabelaPreco(
  tabelaPrecoUuid: string,
  params: HttpRequestInterface<QueryParamsInterface>,
) {
  const { pagination, queryParams } = params
  const request: RequestInterface<QueryParamsInterface> = {
    route: `administrador/tabela-preco/${tabelaPrecoUuid}/itens`,
    pagination,
    queryParams,
  }

  return read<QueryParamsInterface, ItemTabelaPrecoModel>(request)
}

export async function obterItemPorTabelaPrecoEProduto(
  tabelaPrecoUuid: string,
  produtoUuid: string,
) {
  const response = await api.get(
    `administrador/tabela-preco/${tabelaPrecoUuid}/item/${produtoUuid}`,
  )
  return response.data
}

export async function obterTabelaPrecoPorRegra(
  params: QueryParamsInterface,
): Promise<TabelaPrecoModel[]> {
  const response = await api.get<TabelaPrecoModel[]>(
    `vendas/tabela-preco/tabela-preco-por-regra`,
    {
      params,
    },
  )
  return response.data
}

export async function obterProdutosNaoVinculados(
  params: QueryParamsInterface,
): Promise<HttpResponseInterface<ProdutoModel>> {
  const { pagination: _pagination, tabelaPrecoUuid, plainQuery } = params

  const response = await api.get(
    `administrador/tabela-preco/${tabelaPrecoUuid}/produtos-nao-vinculados`,
    {
      params: { plainQuery },
      headers: {
        'DC-Page': _pagination?.page,
        'DC-PageSize': _pagination?.pageSize,
      },
    },
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export async function obterDescontoAgregado(): Promise<
  HttpResponseInterface<DescontoAgregadoModel>
> {
  const response = await api.get(`/administrador/desconto-agregado`)

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export async function obterDescontoAgregadoPorTabelaId(
  tabelaPrecoUuid: string,
): Promise<HttpResponseInterface<DescontoAgregadoModel>> {
  const response = await api.get(
    `/administrador/tabela-preco/${tabelaPrecoUuid}/desconto-agregado`,
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export async function adicionarDescontoAgregado(
  data: DescontoAgregadoModel,
): Promise<DescontoAgregadoModel> {
  const response = await api.post(`/administrador/desconto-agregado`, data)
  return response.data
}

export async function removerDescontoAgregado(
  data: DescontoAgregadoModel,
): Promise<DescontoAgregadoModel> {
  await api.delete(`/administrador/desconto-agregado/${data?.uuid}`)
  return data
}

export async function vincularProdutos(
  tabelaPrecoUuid: string,
  produtosSelecionadosIds: Array<string>,
): Promise<TabelaPrecoModel> {
  const response = await api.put(
    `administrador/tabela-preco/${tabelaPrecoUuid}/vincular-produtos`,
    produtosSelecionadosIds,
  )
  return response.data
}

export async function vincularProdutosComPreco(
  tabelaPrecoUuid: string,
  produtoUuid: string[],
  preco: string,
): Promise<TabelaPrecoModel> {
  const response = await api.put(
    `administrador/tabela-preco/${tabelaPrecoUuid}/vincular-produtos-com-preco`,
    { produtoUuid, preco },
  )
  return response.data
}

export async function desvincularProdutos(
  tabelaPrecoUuid: string,
  produtosSelecionadosIds: Array<string>,
): Promise<ItemTabelaPrecoModel> {
  const response = await api.put(
    `/administrador/tabela-preco/${tabelaPrecoUuid}/desvincular-produtos`,
    produtosSelecionadosIds,
  )

  return response.data
}

export function useQueryItemTabelaPreco(
  tabelaPrecoUuid: string,
  params: HttpRequestInterface<QueryParamsInterface>,
) {
  return useQuery<HttpResponseInterface<ItemTabelaPrecoModel>, AxiosError>(
    ['ITEM_TABELA_PRECO', tabelaPrecoUuid, params],
    () => obterItemTabelaPreco(tabelaPrecoUuid, params),
  )
}

export function useQueryObterTabelaPrecoPorRegra(params: QueryParamsInterface) {
  return useQuery<
    TabelaPrecoModel[],
    AxiosError<ErrorInterface, ErrorInterface>
  >(['TABELAPRECO', params], () => {
    return obterTabelaPrecoPorRegra(params)
  })
}

export function useQueryObterDescontoAgregado() {
  return useQuery<HttpResponseInterface<DescontoAgregadoModel>, AxiosError>(
    ['DESCONTO_AGREGADO'],
    () => {
      return obterDescontoAgregado()
    },
  )
}

export function useQueryObterDescontoAgregadoPorTabelaId(
  tabelaPrecoUuid: string,
) {
  return useQuery<HttpResponseInterface<DescontoAgregadoModel>, AxiosError>(
    ['DESCONTO_AGREGADO', tabelaPrecoUuid],
    () => {
      return obterDescontoAgregadoPorTabelaId(tabelaPrecoUuid)
    },
  )
}

export function useAdicionarDescontoAgregado() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<DescontoAgregadoModel, AxiosError, DescontoAgregadoModel>(
    (data: DescontoAgregadoModel) => adicionarDescontoAgregado(data),
    {
      onSuccess(_: DescontoAgregadoModel) {
        notifications.notifySuccess('Desconto Agregado incluído com sucesso!')
        queryClient.invalidateQueries(['DESCONTO_AGREGADO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useRemoverDescontoAgregado() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError, DescontoAgregadoModel>(
    (data: DescontoAgregadoModel) => removerDescontoAgregado(data),
    {
      onSuccess(_: DescontoAgregadoModel) {
        notifications.notifySuccess('Desconto Agregado excluído com sucesso!')
        queryClient.invalidateQueries(['DESCONTO_AGREGADO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useQueryObterProdutosNaoVinculados(
  params: QueryParamsInterface,
) {
  return useQuery<
    HttpResponseInterface<ProdutoModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['ITEM_TABELA_PRECO', params], () => {
    return obterProdutosNaoVinculados(params)
  })
}

export function useVincularProdutos() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<TabelaPrecoModel, AxiosError, VinculoProdutosInputProps>(
    (data: VinculoProdutosInputProps) =>
      vincularProdutos(data.tabelaPrecoUuid, data.produtosSelecionadosIds),
    {
      onSuccess(_: TabelaPrecoModel) {
        // const flex = flexPluralSingular(data.clientesSelecionadosIds)

        notifications.notifySuccess(`Produtos vinculados com sucesso`)
        queryClient.invalidateQueries(['ITEM_TABELA_PRECO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useVincularProdutosComPreco() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    TabelaPrecoModel,
    AxiosError,
    VinculoProdutosComPrecoInputProps
  >(
    (data: VinculoProdutosComPrecoInputProps) =>
      vincularProdutosComPreco(
        data.tabelaPrecoUuid,
        data.produtoUuid,
        data.preco,
      ),
    {
      onSuccess(_: TabelaPrecoModel) {
        // const flex = flexPluralSingular(data.clientesSelecionadosIds)

        notifications.notifySuccess(`Produtos vinculados com sucesso`)
        queryClient.invalidateQueries(['ITEM_TABELA_PRECO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useDesvincularProdutos() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    ItemTabelaPrecoModel,
    AxiosError,
    VinculoProdutosInputProps
  >(
    (data: VinculoProdutosInputProps) =>
      desvincularProdutos(data.tabelaPrecoUuid, data.produtosSelecionadosIds),
    {
      onSuccess(_: ItemTabelaPrecoModel) {
        // const flex = flexPluralSingular<string>(data.clientesSelecionadosIds)

        notifications.notifySuccess(`Produtos desvinculados com sucesso`)
        queryClient.invalidateQueries(['ITEM_TABELA_PRECO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function updatePrecoProdutosVinculados(
  data: Array<UpdateVinculoProdutosInputProps>,
  tabelaPrecoUuid: string,
): Promise<ItemTabelaPrecoModel> {
  const response = await api.put(
    `administrador/tabela-preco/${tabelaPrecoUuid}/itens`,
    data,
  )
  return response.data
}

export function useUpdatePrecoProdutosVinculados() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    ItemTabelaPrecoModel,
    AxiosError,
    {
      tabelaPrecoUuid: string
      item: Array<UpdateVinculoProdutosInputProps>
    }
  >((data) => updatePrecoProdutosVinculados(data.item, data.tabelaPrecoUuid), {
    onSuccess(_: ItemTabelaPrecoModel) {
      notifications.notifySuccess(`Produtos alterados com sucesso`)
      queryClient.invalidateQueries(['ITEM_TABELA_PRECO'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export default function useTabelaPreco() {
  return {
    ...useCrud<TabelaPrecoModel, AxiosError>(
      'administrador/tabela-preco',
      'Tabela de Preço',
      'female',
    ),
    useQueryObterDescontoAgregado,
    useQueryObterDescontoAgregadoPorTabelaId,
    useAdicionarDescontoAgregado,
    useRemoverDescontoAgregado,
    useQueryObterProdutosNaoVinculados,
    useVincularProdutos,
    useDesvincularProdutos,
    useQueryItemTabelaPreco,
    useUpdatePrecoProdutosVinculados,
  }
}
