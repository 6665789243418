import { ReactNode, useEffect, useMemo } from 'react'

import { Box, useTheme } from '@mui/material'

import Menu, { MenuItemProps } from 'components/Menu'
import Header from 'components/Header'

import { Navigate } from 'react-router-dom'
import Fallback from 'components/Fallback'
import * as Sentry from '@sentry/react'
import { useUserContext } from 'components/Contexts/UserContext'
import api from 'services/api'
import socket from 'services/ws'
import { useCredentials } from '@data-c/hooks'
import credentials from 'configs/credentials'
import Unauthorized from 'components/Displays/Unauthorized'
import bgImage from 'assets/images/fdvbg.jpeg'
interface LayoutProps {
  children: ReactNode
  isProtected: boolean
  role?: string
}

const Layout = (props: LayoutProps) => {
  const { children, isProtected, role } = props
  const { isAuthenticated } = useCredentials(credentials)
  const theme = useTheme()
  const { user, temPermissao, loadConfiguracoes, loadUser, isLoading } =
    useUserContext()

  if (isProtected && !isAuthenticated) {
    return <Navigate to="/login" replace />
  }

  function registerSw() {
    navigator.serviceWorker
      .register('/service-worker.js', { scope: '/' })
      .then(async (sw) => {
        sw.update()
        let subscription = await sw.pushManager.getSubscription()
        if (!subscription) {
          subscription = await sw.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey:
              'BKRP8i5k9eVt6LHURZ17oFyJREolcDkmJsXjEAjvVrW4bwcpU29Ft5d532pO5Ld7yqrcFQa9m9WRYJtt7qt5-sA',
          })
          await api.post('webpush/register', subscription)
        }
      })
      .catch((e) => console.log('SW', e))
  }

  useEffect(() => {
    loadUser()
    loadConfiguracoes()
  }, [])

  let podeAcessar = true

  if (role && !temPermissao(role)) {
    podeAcessar = false
  }

  useEffect(() => {
    function onConnect() {
      socket.emit('join', user?.uuid)
    }
    function onDisconnect() {}
    function onError(_: any) {}

    if (user) {
      // setUser(user)
      registerSw()
      socket.on('connect', onConnect)
      socket.on('disconnect', onDisconnect)
      socket.on('error', onError)
      socket.connect()

      return () => {
        socket.off('connect', onConnect)
        socket.off('disconnect', onDisconnect)
        socket.off('error', onError)
        socket.disconnect()
      }
    }
  }, [user])

  const menuItems: Array<MenuItemProps> = useMemo((): Array<MenuItemProps> => {
    function visibleIn(
      environments: Array<'production' | 'development'>,
      onlyAdmin: boolean = false,
    ): boolean {
      const environment: 'production' | 'development' =
        (import.meta.env.VITE_ENV as 'production' | 'development') ||
        'development'

      let hasPermission = true

      if (onlyAdmin) {
        hasPermission = user?.isadmin || false
      }

      return environments.includes(environment) && hasPermission
    }

    return [
      {
        id: 'menu_negociacoes',
        label: 'Negociações',
        icon: 'shopping_basket',
        link: '/pedidos/lista',
        visible:
          visibleIn(['development', 'production']) &&
          temPermissao('negociacoes.visibilidade'),
      },
      {
        id: 'menu_cadastros',
        label: 'Cadastros',
        icon: 'newspaper',
        visible:
          visibleIn(['development', 'production']) &&
          temPermissao('cadastros.visibilidade'),
        items: [
          {
            id: 'menu_clientes',
            label: 'Clientes',
            icon: 'groups',
            link: '/clientes/lista',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('clientes.visibilidade'),
          },
          {
            id: 'menu_vendedores',
            label: 'Vendedores',
            link: '/vendedores/lista',
            icon: 'hail',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('vendedores.visibilidade'),
          },
          {
            id: 'menu_tabela_precos',
            label: 'Tabela de Preços',
            icon: 'price_change',
            link: '/tabelas-precos/lista',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('tabela_precos.visibilidade'),
            // visible: false,
          },
          {
            id: 'menu_condicao_pagamento',
            label: 'Condição de Pgto.',
            icon: 'date_range',
            link: '/condicao-pagamento',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('condicao_pagamento.visibilidade'),
          },
          {
            id: 'menu_forma_pagamento',
            label: 'Forma de Pagamento',
            icon: 'payment',
            link: '/forma-pagamento/lista',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('forma_pagamento.visibilidade'),
          },
          {
            id: 'menu_fator_precificacao',
            label: 'Fator de Precificação',
            icon: 'price_change',
            link: '/fator-precificacao',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('fator_precificacao.visibilidade'),
          },
          // {
          //   id: 'importacao',
          //   label: 'Importar',
          //   icon: 'newspaper',
          //   link: '/importacao',
          //   visible: visibleIn(['development', 'production']),
          // },
          {
            id: 'menu_produtos',
            label: 'Produtos',
            icon: 'shopping_bag',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('produtos.visibilidade'),
            items: [
              {
                id: 'menu_produtos_listamenu_produtos_lista',
                label: 'Produtos',
                icon: 'circle',
                link: '/produtos/lista',
                visible:
                  visibleIn(['development', 'production']) &&
                  temPermissao('produtos.visibilidade'),
              },
              {
                id: 'menu_mix_produtos',
                label: 'Mix de Produtos',
                icon: 'circle',
                link: '/mix-produtos/lista',
                visible:
                  visibleIn(['development', 'production']) &&
                  temPermissao('mix_produtos.visibilidade'),
              },
              {
                id: 'menu_linha_produtos',
                label: 'Linha de Produtos',
                icon: 'circle',
                link: '/linha-produtos',
                visible:
                  visibleIn(['development', 'production']) &&
                  temPermissao('produtos_linha.visibilidade'),
              },
              {
                id: 'menu_grupo_produtos',
                label: 'Grupo de Produtos',
                icon: 'circle',
                link: '/grupo-produtos',
                visible:
                  visibleIn(['development', 'production']) &&
                  temPermissao('produtos_grupo.visibilidade'),
              },
              {
                id: 'menu_familia_produtos',
                label: 'Família de Produtos',
                icon: 'circle',
                link: '/familia-produtos',
                visible:
                  visibleIn(['development', 'production']) &&
                  temPermissao('produtos_familia.visibilidade'),
              },
              {
                id: 'menu_classe_produtos',
                label: 'Classe de Produtos',
                icon: 'circle',
                link: '/classe-produtos',
                visible:
                  visibleIn(['development', 'production']) &&
                  temPermissao('produtos_classe.visibilidade'),
              },
            ],
          },
        ],
      },
      {
        id: 'menu_crm',
        label: 'CRM',
        icon: 'support_agent',
        visible:
          visibleIn(['development', 'production']) &&
          temPermissao('crm.visibilidade'),
        items: [
          {
            id: 'menu_leads',
            label: 'Leads',
            icon: 'groups',
            link: '/clientes/lista',
            visible:
              visibleIn(['development']) && temPermissao('leads.visibilidade'),
          },
          {
            id: '16',
            label: 'Atividades',
            icon: 'circle',
            link: '/crm/atividades',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('atividades.visibilidade'),
          },
          {
            id: '17',
            label: 'Motivos de Perda',
            icon: 'circle',
            link: '/motivos-de-perda',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('motivos_perda.visibilidade'),
          },
          // {
          //   id: 'menu_funil_venda',
          //   label: 'Funil de Vendas',
          //   icon: 'circle',
          //   link: '/funil-venda',
          //   visible:
          //     visibleIn(['development', 'production']) &&
          //     temPermissao('crm_funil_venda.visibilidade'),
          // },
        ],
      },
      {
        id: 'menu_relatorios',
        label: 'Relatórios',
        icon: 'bar_chart',
        visible:
          visibleIn(['development', 'production']) &&
          temPermissao('relatorios.visibilidade'),
        items: [
          {
            id: 'relatorio-performance-produtos',
            label: 'Performance de Produtos',
            icon: 'bar_chart',
            link: '/relatorio-performance-produtos',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('performance_produtos.visibilidade'),
            isNew: true,
          },
          {
            id: 'taxa_conversao_negocio',
            label: 'Taxa Conversão Negócio',
            icon: 'bar_chart',
            link: '/taxa-conversao-negocio',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('taxa_conversao_negocio.visibilidade'),
          },
          {
            id: 'abc_produtos',
            label: 'Curva ABC Produtos',
            icon: 'bar_chart',
            link: '/abc-produtos',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('abc_produtos.visibilidade'),
          },
          {
            id: 'abc_clientes',
            label: 'Curva ABC Clientes',
            icon: 'bar_chart',
            link: '/abc-clientes',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('abc_clientes.visibilidade'),
          },
          {
            id: 'menu_meta_vendas',
            label: 'Meta de Vendas',
            icon: 'bar_chart',
            link: '/meta-vendas',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('meta_vendas.visibilidade'),
          },
          {
            id: 'menu_ficha_cliente',
            label: 'Ficha do Cliente',
            icon: 'bar_chart',
            link: '/ficha-clientes',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('ficha_clientes.visibilidade'),
          },
          {
            id: 'menu_ativacao_clientes',
            label: 'Ativação de Clientes',
            icon: 'bar_chart',
            link: '/ativacao-clientes',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('positivacao_clientes.visibilidade'),
          },
          {
            id: 'menu_negociacoes_perdidas',
            label: 'Oportunidades Perdidas',
            icon: 'bar_chart',
            link: '/negociacoes-perdidas',
            visible:
              visibleIn(['development', 'production']) &&
              temPermissao('negociacoes_perdidas.visibilidade'),
          },
        ],
      },
      {
        id: 'menu_configuracoes',
        label: 'Configurações',
        icon: 'settings',
        link: '/configuracoes',
        visible:
          visibleIn(['development']) &&
          temPermissao('configuracoes.visibilidade'),
      },
    ]
  }, [user])

  return (
    <Box
      sx={{
        backgroundImage: `url('${bgImage}')`,
        backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center',
        height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
        display: 'flex',
        marginTop: `${theme.mixins.toolbar.height}px`,
      }}
    >
      <Header />
      <Menu menus={menuItems} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: '1 1 0%',
          position: 'relative',
          overflow: 'auto',
        }}
      >
        <Sentry.ErrorBoundary
          fallback={(errorProps) => <Fallback {...errorProps} />}
        >
          {podeAcessar && children}
          {!podeAcessar && !isLoading && <Unauthorized />}
        </Sentry.ErrorBoundary>
      </Box>
    </Box>
  )
}

Layout.defaultProps = {
  isProtected: true,
}

export default Layout
